import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import styled, { withTheme } from "styled-components"
import AudioProgressBar from "components/AudioProgressBar"
import MarketplaceAudioProgressBar from "components/MarketplaceAudioProgressBar"
import Icon from "components/Icon"
import { useSelector } from "react-redux"
import { actions as modalActions } from "ducks/modal"
import { actions as favoritableActions } from "ducks/favoritable"
import { loggedIn } from "utils/authentication"
import { selectSearchSidebarOpen, selectSfxSearchSidebarOpen } from "ducks/ui"
import useCurrentUser from "hooks/useCurrentUser"
import useMetadataSlideout from "hooks/useMetadataSlideout"

import {
  Song as SongRecord,
  MarketplaceSong as MarketplaceSongRecord,
} from "records"

import {
  ICON_SS_PLAY,
  ICON_SS_PAUSE,
  ICON_SS_HEART_FULL,
  ICON_SS_HEART_EMPTY,
  ICON_SS_ELLIPSIS,
} from "components/Icon/constants"

const MobileViewWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 4rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.fixed};
`

const DurationBar = styled.div`
  height: 2px;
  overflow: hidden;
  padding: 0;
`

const InfoSection = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`

const ClickWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 0;
  min-width: 0;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
`

const SongImgWrapper = styled.div`
  border-radius: ${({ theme }) => theme.radii.default};
  height: 40px;
  margin-right: ${({ theme }) => theme.space[3]};
  overflow: hidden;
  width: 40px;
`

const SongImg = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`

const TitleSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.space[3]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleItem = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  &.artist {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`

const ControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0;
  height: 100%;
  justify-content: flex-end;
  margin-right: ${({ theme }) => theme.space[2]};
`

const IconWrapper = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.space[3]};
`

const MobileView = ({
  duration,
  handleTogglePause,
  index,
  isPlaying,
  storeKey,
  song,
  theme,
}) => {
  const { currentUser } = useCurrentUser()
  const dispatch = useDispatch()
  const { enabled: metadataSlideout } = useMetadataSlideout()

  const searchSidebarIsOpen = useSelector(selectSearchSidebarOpen())
  const sfxSidebarIsOpen = useSelector(selectSfxSearchSidebarOpen())

  const marketSong = song.type === "marketplace_songs"

  const ProgressBarComponent = marketSong
    ? MarketplaceAudioProgressBar
    : AudioProgressBar

  const songFavorited = marketSong
    ? currentUser.favoritedMarketplaceSong(song.id)
    : currentUser.favoritedSong(song.id)

  const favorite = () => dispatch(favoritableActions.favorite(song))

  const handleToggleFavorite = () => {
    if (!song) return

    if (loggedIn()) {
      if (songFavorited) {
        dispatch(favoritableActions.unfavorite(song))
      } else {
        favorite()
      }
    } else {
      dispatch(
        modalActions.open("SignUpModal", {
          action: marketSong ? "marketplace_favorite" : "favorite",
          afterSignup: favorite,
        })
      )
    }
  }

  const openMobileSongMenuModal = () => {
    if (metadataSlideout && !marketSong) {
      dispatch(
        modalActions.open("MetadataSlideout", {
          song: song,
          index: index,
          storeKey: storeKey,
          isMobile: true,
        })
      )
    } else {
      const modalName = marketSong
        ? "MarketplaceMobileSongMenuModal"
        : "MobileSongMenuModal"

      dispatch(
        modalActions.open(modalName, {
          song: song,
          index: index,
          storeKey: storeKey,
        })
      )
    }
  }

  if (searchSidebarIsOpen || sfxSidebarIsOpen) {
    return null
  }

  return (
    <MobileViewWrapper>
      {duration > 0 && (
        <DurationBar>
          <ProgressBarComponent
            onClick={openMobileSongMenuModal}
            onChange={() => {}}
            showDot={false}
            showDuration={false}
          />
        </DurationBar>
      )}
      <InfoSection>
        <ClickWrapper onClick={openMobileSongMenuModal}>
          <SongImgWrapper>
            <SongImg
              src={marketSong ? song.pic.url : song.primaryArtistPicUrl()}
            />
          </SongImgWrapper>
          <TitleSection>
            <TitleItem>{song.title}</TitleItem>
            <TitleItem className="artist">{song.primaryArtistName()}</TitleItem>
          </TitleSection>
          <Icon class="pointer" icon={ICON_SS_ELLIPSIS} size={24} />
        </ClickWrapper>
        <ControlsWrapper>
          <IconWrapper onClick={handleToggleFavorite}>
            <Icon
              icon={songFavorited ? ICON_SS_HEART_FULL : ICON_SS_HEART_EMPTY}
              color={songFavorited ? theme.colors.text.accent : undefined}
              size={24}
            />
          </IconWrapper>
          <IconWrapper onClick={handleTogglePause}>
            <Icon icon={isPlaying ? ICON_SS_PAUSE : ICON_SS_PLAY} size={24} />
          </IconWrapper>
        </ControlsWrapper>
      </InfoSection>
    </MobileViewWrapper>
  )
}

export default withTheme(MobileView)

MobileView.propTypes = {
  duration: PropTypes.number,
  handleTogglePause: PropTypes.func.isRequired,
  index: PropTypes.number,
  isPlaying: PropTypes.bool.isRequired,
  storeKey: PropTypes.string,
  song: PropTypes.oneOfType([
    PropTypes.instanceOf(SongRecord),
    PropTypes.instanceOf(MarketplaceSongRecord),
  ]).isRequired,
  theme: PropTypes.object,
}
