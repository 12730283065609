import PropTypes from "prop-types"
import React, { memo } from "react"
import styled from "styled-components"
import { Link } from "react-router"

import {
  Song as SongRecord,
  MarketplaceSong as MarketplaceSongRecord,
} from "records"

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1;
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 1.25rem;
`

const StyledImg = styled.img`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: ${({ theme }) => theme.radii.default};
  box-shadow: ${({ theme }) => theme.shadows.default};
  display: block;
  user-select: none;
  object-fit: cover;
`

const LinkWrapper = styled.div`
  width: 147px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.artist {
    color: ${({ theme }) => theme.colors.text.secondary};
    margin-top: -7px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding-left: 3px;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
  width: 150px;

  a > &.artist {
    &:after {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  @media (max-width: ${({ theme }) => theme.flexboxgrid.breakpoints.md}em) {
    width: 120px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl2}) {
    width: 200px;
  }
`

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  user-select: none;
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: none;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.artist {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text.secondary};

    &:not(:last-child) {
      margin-right: 4px;

      &:after {
        content: ",";
        display: inline-block;
        color: ${({ theme }) => theme.colors.text.secondary} !important;
        text-decoration: none !important;
      }
    }
  }

  &:not(.no-link) {
    &:hover {
      color: ${({ theme }) => theme.colors.action.hover};
      cursor: pointer;
    }
  }
`

const InfoSection = ({ song }) => {
  const marketSong = song.type === "marketplace_songs"

  const imageSrc = marketSong
    ? song.pic.versions?.thumb.url
    : song.primaryArtistPicThumbnailUrl()

  return (
    <Wrapper>
      <ImgWrapper>
        <StyledImg src={imageSrc} />
      </ImgWrapper>
      <InfoWrapper>
        <LinkWrapper>
          <StyledLink
            className={marketSong ? "no-link" : undefined}
            to={marketSong ? undefined : `/songs/${song.id}`}
          >
            {song.title}
          </StyledLink>
        </LinkWrapper>
        <LinkWrapper className="artist">
          {song.artist_credits.map((artist, i) => {
            const artistLink = marketSong
              ? `/market/artists/${artist.slugified_name}-${artist.id}}`
              : `/artists/${artist.id}`

            return (
              <StyledLink key={i} className="artist" to={artistLink}>
                {artist.name}
              </StyledLink>
            )
          })}
        </LinkWrapper>
      </InfoWrapper>
    </Wrapper>
  )
}

export default memo(InfoSection)

InfoSection.propTypes = {
  song: PropTypes.oneOfType([
    PropTypes.instanceOf(SongRecord),
    PropTypes.instanceOf(MarketplaceSongRecord),
  ]).isRequired,
}
