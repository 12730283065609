import { fromJS } from "immutable"
import { createSelector } from "reselect"
import { User as UserRecord } from "records"
import {
  createRecordsFromResponse,
  createUserRecordsFromSignInResponse,
  createUserRecordFromSignInResponse,
} from "records/util"
import {
  insertOrUpdateCurrentUserRecords,
  setLiveChatUserInfo,
  addFavoritedIdToList,
  removeFavoritedIdFromList,
} from "ducks/utils/currentUser"
import { storeToken, removeToken } from "utils/authentication"
import { createAction } from "./utils"

export const types = {
  UPSELL_SUBMIT: "soundstripe/currentUser/UPSELL_SUBMIT",
  REACTIVATE_SUBMIT: "soundstripe/currentUser/REACTIVATE_SUBMIT",
  TRACK_UPSELL: "soundstripe/currentUser/TRACK_UPSELL",
  TRACK_UPSELL_ATTEMPT: "soundstripe/currentUser/TRACK_UPSELL_ATTEMPT",
  PREMIUM_UPGRADE_DISCOUNT_SUBMIT:
    "soundstripe/currentUser/PREMIUM_UPGRADE_DISCOUNT_SUBMIT",
  CANCEL_SUBSCRIPTION_SUBMIT:
    "soundstripe/currentUser/CANCEL_SUBSCRIPTION_SUBMIT",
  FORGOT_PASSWORD_SUBMIT: "soundstripe/currentUser/FORGOT_PASSWORD_SUBMIT",
  FORGOT_PASSWORD_SUCCESS: "soundstripe/currentUser/FORGOT_PASSWORD_SUCCESS",
  LOAD: "soundstripe/currentUser/LOAD",
  PREVIEW_UPCOMING_INVOICE_SUBMIT:
    "soundstripe/currentUser/PREVIEW_UPCOMING_INVOICE_SUBMIT",
  PREVIEW_UPCOMING_INVOICE_COMPLETE:
    "soundstripe/currentUser/PREVIEW_UPCOMING_INVOICE_COMPLETE",
  REQUEST_SUCCESS: "soundstripe/currentUser/REQUEST_SUCCESS",
  REQUEST_FAILED: "soundstripe/currentUser/REQUEST_FAILED",
  CLEAR_REQUEST_FAILED: "soundstripe/currentUser/CLEAR_REQUEST_FAILED",
  SIGN_IN_SUBMIT: "soundstripe/currentUser/SIGN_IN_SUBMIT",
  SIGN_IN_SUCCESS: "soundstripe/currentUser/SIGN_IN_SUCCESS",
  SIGN_UP_SUBMIT: "soundstripe/currentUser/SIGN_UP_SUBMIT",
  ENTERPRISE_SIGN_UP_SUBMIT:
    "soundstripe/currenntUser/ENTERPRISE_SIGN_UP_SUBMIT",
  ENTERPRISE_MODAL_SIGN_UP_SUBMIT:
    "soundstripe/currentUser/ENTERPRISE_MODAL_SIGN_UP_SUBMIT",
  CONTACT_LICENSING_MODAL_SUBMIT:
    "soundstripe/currentUser/CONTACT_LICENSING_MODAL_SUBMIT",
  CONTACT_SFX_LICENSING_MODAL_SUBMIT:
    "soundstripe/currentUser/CONTACT_SFX_LICENSING_MODAL_SUBMIT",
  TEAM_SEAT_MAX_MODAL_SUBMIT:
    "soundstripe/currentUser/TEAM_SEAT_MAX_MODAL_SUBMIT",
  SIGN_OUT: "soundstripe/currentUser/SIGN_OUT",
  SIGN_OUT_EVERYWHERE_SUBMIT:
    "soundstripe/currentUser/SIGN_OUT_EVERYWHERE_SUBMIT",
  GOOGLE_OAUTH_SUBMIT: "soundstripe/currentUser/GOOGLE_OAUTH_SUBMIT",
  GOOGLE_OAUTH_SUCCESS: "soundstripe/currentUser/GOOGLE_OAUTH",
  GOOGLE_OAUTH_FAILED: "soundstripe/currentUser/GOOGLE_OAUTH_FAILED",
  UNLINK_OAUTH_ACCOUNTS_SUBMIT:
    "soundstripe/currentUser/UNLINK_OAUTH_ACCOUNTS_SUBMIT",
  UNLINK_OAUTH_ACCOUNTS_COMPLETE:
    "soundstripe/currentUser/UNLINK_OAUTH_ACCOUNTS_COMPLETE",
  UPDATE: "soundstripe/currentUser/UPDATE",
  CLEAR_AVATAR_SUBMIT: "soundstripe/currentUser/CLEAR_AVATAR_SUBMIT",
  CLEAR_AVATAR_COMPLETE: "soundstripe/currentUser/CLEAR_AVATAR_COMPLETE",
  UPDATE_AVATAR_SUBMIT: "soundstripe/currentUser/UPDATE_AVATAR_SUBMIT",
  UPDATE_AVATAR_COMPLETE: "soundstripe/currentUser/UPDATE_AVATAR_COMPLETE",
  UPDATE_BILLING_INFO_SUBMIT:
    "soundstripe/currentUser/UPDATE_BILLING_INFO_SUBMIT",
  UPDATE_PROFILE_SUBMIT: "soundstripe/currentUser/UPDATE_PROFILE_SUBMIT",
  CHANGE_PASSWORD_SUBMIT: "soundstripe/currentUser/CHANGE_PASSWORD_SUBMIT",
  CHECKOUT_FORM_SUBMIT: "soundstripe/currentUser/CHECKOUT_FORM_SUBMIT",
  PAYMENT_REQUEST_BUTTON_SUBMIT:
    "soundstripe/currentUser/PAYMENT_REQUEST_BUTTON_SUBMIT",
  PAYMENT_REQUEST_BUTTON_SUCCESS:
    "soundstripe/currentUser/PAYMENT_REQUEST_BUTTON_SUCCESS",
  ESTIMATE_SUBSCRIPTION: "soundstripe/currentUser/ESTIMATE_SUBSCRIPTION",
  ESTIMATE_SUBSCRIPTION_SUCCESS:
    "soundstripe/currentUser/ESTIMATE_SUBSCRIPTION_SUCCESS",
  ESTIMATE_SUBSCRIPTION_FAILED:
    "soundstripe/currentUser/ESTIMATE_SUBSCRIPTION_FAILED",
  CLEAR_ESTIMATE_ERROR: "soundstripe/currentUser/CLEAR_ESTIMATE_ERROR",
  CHARGEBEE_PORTAL_OPEN: "soundstripe/currentUser/CHARGEBEE_PORTAL_OPEN",
  CHARGEBEE_PORTAL_OPEN_SUCCESS:
    "soundstripe/currentUser/CHARGEBEE_PORTAL_OPEN_SUCCESS",
  CREATE_OR_UPDATE_PAYMENT_INTENT:
    "soundstripe/currentUser/CREATE_OR_UPDATE_PAYMENT_INTENT",
  CREATE_STRIPE_PAYMENT_INTENT:
    "soundstripe/currentUser/CREATE_STRIPE_PAYMENT_INTENT",
  CREATE_OR_UPDATE_PAYMENT_INTENT_SUCCESS:
    "soundstripe/currentUser/CREATE_OR_UPDATE_PAYMENT_INTENT_SUCCESS",
  REMOVE_PAYMENT_INTENT: "soundstripe/currentUser/REMOVE_PAYMENT_INTENT",
  RETRIEVE_PAYMENT_SOURCE: "soundstripe/currentUser/RETRIEVE_PAYMENT_SOURCE",
  REMOVE_PAYMENT_SOURCE: "soundstripe/currentUser/REMOVE_PAYMENT_SOURCE",
  RETRIEVE_PAYMENT_SOURCE_SUCCESS:
    "soundstripe/currentUser/RETRIEVE_PAYMENT_SOURCE_SUCCESS",
  RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES:
    "soundstripe/currentUser/RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES",
  RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES_SUCCESS:
    "soundstripe/currentUser/RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES_SUCCESS",
  PAYPAL_CHECKOUT_START: "soundstripe/currentUser/PAYPAL_CHECKOUT_START",
  CART_CHECKOUT_FORM_SUBMIT:
    "soundstripe/currentUser/CART_CHECKOUT_FORM_SUBMIT",
  SINGLE_SONG_CHECKOUT_FORM_SUBMIT:
    "soundstripe/currentUser/SINGLE_SONG_CHECKOUT_FORM_SUBMIT",
  SYNC_SUBSCRIPTION: "soundstripe/currentUser/SYNC_SUBSCRIPTION",
  PAY_DELINQUENT_INVOICES: "soundstripe/currentUser/PAY_DELINQUENT_INVOICES",
  UPDATE_COUPON: "soundstripe/currentUser/UPDATE_COUPON",
  AMOBEE_SUCCESS: "soundstripe/currentUser/AMOBEE_SUCCESS",
  ADD_FAVORITED_ID: "soundstripe/currentUser/ADD_FAVORITED_ID",
  REMOVE_FAVORITED_ID: "soundstripe/currentUser/REMOVE_FAVORITED_ID",
  ADD_LICENSE: "soundstripe/currentUser/ADD_LICENSE",
  PROSPERSTACK_CANCEL_SUBSCRIPTION:
    "soundstripe/currentUser/PROSPERSTACK_CANCEL_SUBSCRIPTION",
  INCREMENT_RECENTLY_PLAYED_SONGS_COUNT:
    "soundstripe/currentUser/INCREMENT_RECENTLY_PLAYED_SONGS_COUNT",
  RESET_PRORATED_INV_AMOUNTS:
    "soundstripe/currentUser/RESET_PRORATED_INV_AMOUNTS",
}

export const actions = {
  upsellSubmit: createAction(
    types.UPSELL_SUBMIT,
    "planId",
    "couponCode",
    "isDowngrade"
  ),
  reactivateSubmit: createAction(
    types.REACTIVATE_SUBMIT,
    "planId",
    "couponCode"
  ),
  trackUpsell: createAction(types.TRACK_UPSELL),
  trackUpsellAttempt: createAction(types.TRACK_UPSELL_ATTEMPT),
  cancelSubscriptionSubmit: createAction(
    types.CANCEL_SUBSCRIPTION_SUBMIT,
    "form",
    "values",
    "subscriptionId"
  ),
  prosperstackCancelSubscription: createAction(
    types.PROSPERSTACK_CANCEL_SUBSCRIPTION,
    "values",
    "subscriptionId"
  ),
  forgotPasswordSubmit: createAction(
    types.FORGOT_PASSWORD_SUBMIT,
    "form",
    "email"
  ),
  load: createAction(types.LOAD),
  previewUpcomingInvoiceSubmit: createAction(
    types.PREVIEW_UPCOMING_INVOICE_SUBMIT,
    "newPlanId",
    "couponCode",
    "isDualInvoice"
  ),
  previewUpcomingInvoiceComplete: createAction(
    types.PREVIEW_UPCOMING_INVOICE_COMPLETE,
    "response",
    "amt",
    "isDualInvoice"
  ),
  resetProratedInvoiceAmounts: createAction(types.RESET_PRORATED_INV_AMOUNTS),
  requestSuccess: createAction(types.REQUEST_SUCCESS, "response", "authToken"),
  requestFailed: createAction(types.REQUEST_FAILED),
  clearRequestFailed: createAction(types.CLEAR_REQUEST_FAILED),
  signInSubmit: createAction(
    types.SIGN_IN_SUBMIT,
    "values",
    "redirectPath",
    "callback",
    "tracking"
  ),
  signInSuccess: createAction(types.SIGN_IN_SUCCESS, "response", "authToken"),
  signUpSubmit: createAction(
    types.SIGN_UP_SUBMIT,
    "values",
    "form",
    "redirectPath",
    "accountInviteToken",
    "callback",
    "signupSelectedPlan",
    "tracking"
  ),
  enterpriseSignUpSubmit: createAction(
    types.ENTERPRISE_SIGN_UP_SUBMIT,
    "values",
    "form",
    "signUpToken"
  ),
  enterpriseModalSignUpSubmit: createAction(
    types.ENTERPRISE_MODAL_SIGN_UP_SUBMIT,
    "values",
    "form",
    "hubspotData",
    "signupSelectedPlan",
    "redirectPath",
    "setServerError"
  ),
  contactLicensingModalSubmit: createAction(
    types.CONTACT_LICENSING_MODAL_SUBMIT,
    "values",
    "form",
    "hubspotData"
  ),
  contactSfxLicensingModalSubmit: createAction(
    types.CONTACT_SFX_LICENSING_MODAL_SUBMIT,
    "values",
    "form",
    "hubspotData"
  ),
  teamSeatMaxModalSubmit: createAction(
    types.TEAM_SEAT_MAX_MODAL_SUBMIT,
    "values",
    "form",
    "hubspotData"
  ),
  googleOauthSubmit: createAction(
    types.GOOGLE_OAUTH_SUBMIT,
    "redirectPath",
    "accountInviteToken",
    "callback",
    "signupSelectedPlan",
    "tracking"
  ),
  googleOauthSuccess: createAction(
    types.GOOGLE_OAUTH_SUCCESS,
    "response",
    "authToken"
  ),
  googleOauthFailed: createAction(types.GOOGLE_OAUTH_FAILED, "error"),
  unlinkOauthAccounts: createAction(types.UNLINK_OAUTH_ACCOUNTS_SUBMIT),
  signOut: createAction(types.SIGN_OUT),
  signOutEverywhereSubmit: createAction(types.SIGN_OUT_EVERYWHERE_SUBMIT),
  update: createAction(types.UPDATE, "data", "form", "redirectPath"),
  clearAvatarSubmit: createAction(types.CLEAR_AVATAR_SUBMIT),
  clearAvatarComplete: createAction(types.CLEAR_AVATAR_COMPLETE),
  updateAvatarSubmit: createAction(types.UPDATE_AVATAR_SUBMIT, "file"),
  updateAvatarComplete: createAction(types.UPDATE_AVATAR_COMPLETE),
  updateProfileSubmit: createAction(
    types.UPDATE_PROFILE_SUBMIT,
    "form",
    "values"
  ),
  changePasswordSubmit: createAction(
    types.CHANGE_PASSWORD_SUBMIT,
    "form",
    "values"
  ),
  updateBillingInfoSubmit: createAction(
    types.UPDATE_BILLING_INFO_SUBMIT,
    "form",
    "values",
    "modal"
  ),
  checkoutFormSubmit: createAction(
    types.CHECKOUT_FORM_SUBMIT,
    "form",
    "checkoutAttributes",
    "chargebeeCard"
  ),
  paymentRequestButtonSubmit: createAction(
    types.PAYMENT_REQUEST_BUTTON_SUBMIT,
    "checkoutAttributes",
    "authorizedPaymentIntent"
  ),
  estimateSubscription: createAction(
    types.ESTIMATE_SUBSCRIPTION,
    "estimatesAttributes",
    "invoice",
    "callback"
  ),
  estimateSubscriptionSuccess: createAction(
    types.ESTIMATE_SUBSCRIPTION_SUCCESS,
    "response"
  ),
  retrieveSubscriptionWithScheduledChanges: createAction(
    types.RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES,
    "response"
  ),
  retrieveSubscriptionWithScheduledChangesSuccess: createAction(
    types.RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES_SUCCESS,
    "response"
  ),
  estimateSubscriptionFailed: createAction(
    types.ESTIMATE_SUBSCRIPTION_FAILED,
    "error"
  ),
  clearEstimateError: createAction(types.CLEAR_ESTIMATE_ERROR),
  chargebeePortalOpen: createAction(types.CHARGEBEE_PORTAL_OPEN),
  createOrUpdatePaymentIntent: createAction(
    types.CREATE_OR_UPDATE_PAYMENT_INTENT,
    "attributes"
  ),
  createStripePaymentIntent: createAction(
    types.CREATE_STRIPE_PAYMENT_INTENT,
    "attributes"
  ),
  createOrUpdatePaymentIntentSuccess: createAction(
    types.CREATE_OR_UPDATE_PAYMENT_INTENT_SUCCESS,
    "response"
  ),
  removePaymentIntent: createAction(types.REMOVE_PAYMENT_INTENT),
  retrievePaymentSource: createAction(types.RETRIEVE_PAYMENT_SOURCE),
  removePaymentSource: createAction(types.REMOVE_PAYMENT_SOURCE),
  retrievePaymentSourceSuccess: createAction(
    types.RETRIEVE_PAYMENT_SOURCE_SUCCESS,
    "response"
  ),
  paypalCheckoutStart: createAction(
    types.PAYPAL_CHECKOUT_START,
    "amount",
    "description",
    "redirectPath"
  ),
  cartCheckoutFormSubmit: createAction(
    types.CART_CHECKOUT_FORM_SUBMIT,
    "form",
    "checkoutAttributes",
    "chargebeeCard"
  ),
  syncSubscription: createAction(types.SYNC_SUBSCRIPTION),
  payDelinquentInvoices: createAction(types.PAY_DELINQUENT_INVOICES),
  updateCoupon: createAction(types.UPDATE_COUPON, "value"),
  amobeeSuccess: createAction(types.AMOBEE_SUCCESS),
  paymentRequestButtonSuccess: createAction(
    types.PAYMENT_REQUEST_BUTTON_SUCCESS
  ),
  addFavoritedId: createAction(types.ADD_FAVORITED_ID, "favoriteType", "id"),
  removeFavoritedId: createAction(
    types.REMOVE_FAVORITED_ID,
    "favoriteType",
    "id"
  ),
  addLicense: createAction(types.ADD_LICENSE, "mediaType", "id"),
  incrementRecentlyPlayedSongsCount: createAction(
    types.INCREMENT_RECENTLY_PLAYED_SONGS_COUNT
  ),
}

const initialState = fromJS({
  isLoading: false,
  stripePaymentSubmitting: false,
  avatarIsLoading: false,
  userRecord: new UserRecord(),
  subscriptionWithScheduledChanges: {},
  relationshipRecords: {},
  proratedInvoiceAmount: null,
  proratedDualInvoiceAmount: null,
  totalUpcomingInvoice: null,
  estimate: null,
  paymentSource: null,
  estimateError: null,
  paymentIntent: null,
  hostedPage: null,
  coupon: null,
  requestFailed: false,
  launchAmobeeSuccess: false,
  googleOauthFailed: false,
  googleOauthError: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
    case types.UPSELL_SUBMIT:
    case types.PREMIUM_UPGRADE_DISCOUNT_SUBMIT:
    case types.CANCEL_SUBSCRIPTION_SUBMIT:
    case types.PREVIEW_UPCOMING_INVOICE_SUBMIT:
    case types.RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES:
    case types.GOOGLE_OAUTH_SUBMIT:
    case types.SIGN_IN_SUBMIT:
    case types.REACTIVATE_SUBMIT:
    case types.SIGN_UP_SUBMIT:
    case types.ENTERPRISE_MODAL_SIGN_UP_SUBMIT:
    case types.CONTACT_LICENSING_MODAL_SUBMIT:
    case types.CONTACT_SFX_LICENSING_MODAL_SUBMIT:
    case types.TEAM_SEAT_MAX_MODAL_SUBMIT:
    case types.SIGN_OUT_EVERYWHERE_SUBMIT:
    case types.UPDATE:
    case types.UPDATE_PROFILE_SUBMIT:
    case types.CHANGE_PASSWORD_SUBMIT:
    case types.UPDATE_BILLING_INFO_SUBMIT:
    case types.CHECKOUT_FORM_SUBMIT:
    case types.CART_CHECKOUT_FORM_SUBMIT:
    case types.SINGLE_SONG_CHECKOUT_FORM_SUBMIT:
    case types.ESTIMATE_SUBSCRIPTION:
    case types.CREATE_OR_UPDATE_PAYMENT_INTENT:
    case types.SYNC_SUBSCRIPTION:
    case types.PAY_DELINQUENT_INVOICES:
      return state.set("isLoading", true)
    case types.REQUEST_SUCCESS: {
      if (action.authToken) storeToken(action.authToken)
      const reqRecords = createRecordsFromResponse(action.response)
      return insertOrUpdateCurrentUserRecords(state, reqRecords)
        .set("isLoading", false)
        .set("requestFailed", false)
    }
    case types.ESTIMATE_SUBSCRIPTION_SUCCESS:
      return state
        .set("estimate", action.response)
        .set("estimateError", null)
        .set("isLoading", false)
    case types.ESTIMATE_SUBSCRIPTION_FAILED:
      return state
        .set("estimateError", action.error)
        .set("isLoading", false)
        .set("estimate", null)
    case types.CLEAR_ESTIMATE_ERROR:
      return state.set("estimateError", null)
    case types.CLEAR_REQUEST_FAILED:
      return state.set("requestFailed", false)
    case types.CREATE_OR_UPDATE_PAYMENT_INTENT_SUCCESS:
      return state.set("paymentIntent", action.response).set("isLoading", false)
    case types.REMOVE_PAYMENT_INTENT:
      return state.set("paymentIntent", null)
    case types.REMOVE_PAYMENT_SOURCE:
      return state.set("paymentSource", null)
    case types.GOOGLE_OAUTH_FAILED:
      return state
        .set("googleOauthFailed", true)
        .set("googleOauthError", action.error)
        .set("isLoading", false)
    case types.GOOGLE_OAUTH_SUCCESS:
    case types.SIGN_IN_SUCCESS: {
      storeToken(action.authToken)
      const userRecord = createUserRecordFromSignInResponse(action.response)
      setLiveChatUserInfo(userRecord)
      const signInRecords = createUserRecordsFromSignInResponse(action.response)
      return insertOrUpdateCurrentUserRecords(state, signInRecords)
        .set("isLoading", false)
        .set("googleOauthFailed", false)
    }
    case types.REQUEST_FAILED:
      return state.set("isLoading", false).set("requestFailed", true)
    case types.PREVIEW_UPCOMING_INVOICE_COMPLETE: {
      const proratedInvoiceAmount = action.amt
      const invoiceSelector = action.isDualInvoice
        ? "proratedDualInvoiceAmount"
        : "proratedInvoiceAmount"
      return state
        .set(invoiceSelector, proratedInvoiceAmount)
        .set("totalUpcomingInvoice", action.response)
        .set("isLoading", false)
    }
    case types.RESET_PRORATED_INV_AMOUNTS: {
      return state
        .set("proratedInvoiceAmount", null)
        .set("proratedDualInvoiceAmount", null)
    }
    case types.RETRIEVE_PAYMENT_SOURCE_SUCCESS: {
      return state.set("paymentSource", action.response).set("isLoading", false)
    }
    case types.RETRIEVE_SUBSCRIPTION_WITH_SCHEDULED_CHANGES_SUCCESS: {
      return state
        .set("subscriptionWithScheduledChanges", action.response)
        .set("isLoading", false)
    }
    case types.CLEAR_AVATAR_SUBMIT:
    case types.UPDATE_AVATAR_SUBMIT:
      return state.set("avatarIsLoading", true)
    case types.PAYMENT_REQUEST_BUTTON_SUBMIT:
      return state.set("stripePaymentSubmitting", true).set("isLoading", true)
    case types.PAYMENT_REQUEST_BUTTON_SUCCESS:
      return state.set("stripePaymentSubmitting", false)
    case types.CLEAR_AVATAR_COMPLETE:
    case types.UPDATE_AVATAR_COMPLETE:
      return state.set("avatarIsLoading", false)
    case types.UPDATE_COUPON:
      return state.set("coupon", action.value)
    case types.SIGN_OUT:
      removeToken()
      return initialState
    case types.AMOBEE_SUCCESS:
      return state.set("launchAmobeeSuccess", true)
    case types.ADD_FAVORITED_ID:
      return addFavoritedIdToList(state, action.favoriteType, action.id)
    case types.REMOVE_FAVORITED_ID:
      return removeFavoritedIdFromList(state, action.favoriteType, action.id)
    case types.ADD_LICENSE:
      const licenseTypeLocation = [
        "userRecord",
        "license_history",
        action.mediaType,
      ]
      const id = parseInt(action.id, 10)
      const licenseArray = state.getIn(licenseTypeLocation) || []
      const newLicenseArray = licenseArray.concat([id])
      return state.setIn(licenseTypeLocation, newLicenseArray)
    case types.INCREMENT_RECENTLY_PLAYED_SONGS_COUNT:
      const recentlyPlayedSongsCountLocation = [
        "userRecord",
        "recently_played_songs_count",
      ]
      const currentCount = state.getIn(recentlyPlayedSongsCountLocation)
      const newCount = currentCount + 1
      return state.setIn(recentlyPlayedSongsCountLocation, newCount)
    default:
      return state
  }
}

const selectCurrentUserState = (state) => state.getIn(["ducks", "currentUser"])

export const selectCurrentUserRecord = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("userRecord")
  )

export const selectCurrentUserRequestFailed = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("requestFailed")
  )

export const selectCurrentUserGoogleOauthFailed = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("googleOauthFailed")
  )

export const selectCurrentUserGoogleOauthError = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("googleOauthError")
  )

export const selectCurrentUserIsLoading = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("isLoading")
  )

export const selectCurrentUserAvatarIsLoading = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("avatarIsLoading")
  )

export const selectCurrentUserProratedInvoiceAmount = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("proratedInvoiceAmount")
  )

export const selectCurrentUserProratedDualInvoiceAmount = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("proratedDualInvoiceAmount")
  )

export const selectCurrentUserTotalUpcomingInvoice = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("totalUpcomingInvoice")
  )

export const selectCurrentUserSubscriptionWithScheduledChanges = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("subscriptionWithScheduledChanges")
  )

export const selectCurrentUserEstimate = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("estimate")
  )

export const selectCurrentUserEstimateError = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("estimateError")
  )

export const selectCurrentUserPaymentIntent = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("paymentIntent")
  )

export const selectCurrentUserPaymentSource = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("paymentSource")
  )

export const selectCurrentUserHostedPage = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("hostedPage")
  )

export const selectCurrentUserCoupon = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("coupon")
  )

export const selectCurrentUserStripePaymentSubmitting = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("stripePaymentSubmitting")
  )

export const selectCurrentUserLaunchAmobeeSuccess = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.get("launchAmobeeSuccess")
  )

export const selectCurrentUserFavoritedSoundEffectIds = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.getIn(["userRecord", "favorited_sound_effect_ids"])
  )

export const selectCurrentUserFavoritedVideoIds = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.getIn(["userRecord", "favorited_video_ids"])
  )

export const selectCurrentUserFavoritedMarketplaceSongIds = () =>
  createSelector(selectCurrentUserState, (currentUserState) =>
    currentUserState.getIn(["userRecord", "favorited_marketplace_song_ids"])
  )
