import { useDispatch } from "react-redux"
import { actions as trackingActions } from "ducks/tracking"

const useMarketplaceMixpanelTracking = () => {
  const dispatch = useDispatch()

  const trackMarketplaceMixpanel = (eventName, eventProperties) => {
    dispatch(
      trackingActions.trackMixpanel(eventName, {
        marketplace: true,
        ...eventProperties,
      })
    )
  }

  return {
    trackMarketplaceMixpanel,
  }
}

export default useMarketplaceMixpanelTracking
