import styled from "styled-components"
import AudioContentWrapper from "components/AudioContentWrapper"
import React from "react"
import Actions from "./Actions"
import Cover from "./Cover"
import DottedButton from "./DottedButton"
import Duration from "./Duration"
import ExplicitFlag from "./ExplicitFlag"
import SongType from "./SongType"
import Status from "./Status"
import Title from "./Title"
import Vocals from "./Vocals"
import Waveform from "./Waveform"
import Box from "components/Box"

const MarketplaceSong = ({ children, ...restProps }) => {
  return <AudioContentWrapper {...restProps}>{children}</AudioContentWrapper>
}

const Content = styled(Box)`
  align-items: center;
  display: flex;
  max-width: 100%;
  width: 100%;
`

MarketplaceSong.Actions = Actions
MarketplaceSong.Cover = Cover
MarketplaceSong.Content = Content
MarketplaceSong.DottedButton = DottedButton
MarketplaceSong.Duration = Duration
MarketplaceSong.ExplicitFlag = ExplicitFlag
MarketplaceSong.SongType = SongType
MarketplaceSong.Status = Status
MarketplaceSong.Title = Title
MarketplaceSong.Vocals = Vocals
MarketplaceSong.Waveform = Waveform

export default MarketplaceSong
