import Immutable from "immutable"

const DEFAULT_AVATAR_IMAGE =
  "https://cdn.soundstripe.com/assets/images/fallback/default_image_thumb.png"

const AvatarRecord = Immutable.Record(
  {
    url: null,
    versions: {
      thumb: {
        url: null,
      },
    },
  },
  "Avatar"
)

class Avatar extends AvatarRecord {
  isDefault() {
    return this.thumbnailUrl() === DEFAULT_AVATAR_IMAGE
  }

  thumbnailUrl() {
    return this.versions.thumb.url
  }
}

export default Avatar
