import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import get from "lodash/get"
import key from "keymaster"
import ReactHowler from "react-howler"
import parseInt from "lodash/parseInt"
import { prependApiUrl, post, get as getRequest } from "utils/request"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { selectAlgoliaListByStoreKey } from "ducks/algoliaList"
import { actions as modalActions } from "ducks/modal"
import { actions as favoritableActions } from "ducks/favoritable"
import { MOBILE, withScreenSize } from "hocs/withScreenSize"
import withMarketplaceMixpanelTracking from "hocs/withMarketplaceMixpanelTracking"
import withMixpanelTracking from "hocs/withMixpanelTracking"
import { currentSortIndexForUser } from "utils/algolia"
import { loggedIn } from "utils/authentication"
import { trackAlgolia } from "utils/tracking"
import InfoSection from "./InfoSection"
import RadioControls from "./RadioControls"
import Volume from "./Volume"
import WaveformSection from "./WaveformSection"
import ActionSection from "./ActionSection"
import MobileView from "./MobileView"

import {
  actions,
  AudioPlayerRecord,
  selectAudioPlayer,
  selectAudioPlayerSong,
  selectAudioPlayerPlaylist,
  selectAudioPlayerAudioFile,
  selectAudioPlayerCursor,
} from "ducks/audioPlayer"

import {
  actions as soundEffectsPlayerActions,
  selectSoundEffectsPlayerPlaying,
} from "ducks/soundEffectsPlayer"

import {
  Song as SongRecord,
  MarketplaceSong as MarketplaceSongRecord,
  MarketplaceAudioFile as MarketplaceAudioFileRecord,
  AudioFile as AudioFileRecord,
  User as UserRecord,
} from "records"

const DesktopWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: ${({ theme }) => theme.layout.audioPlayer.desktop.height};
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-top: 1px solid ${({ theme }) => theme.colors.border.default};
  z-index: ${({ theme }) => theme.zIndices.fixed};
  display: flex;
  justify-content: space-between;
`

const PageContentSection = styled.div``

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingAudioPeaks: true,
      mute: false,
      peaks: null,
      src: null,
      resetPos: false,
    }
  }

  async fetchStreamUrl(seekPos) {
    const songId = this.props.song?.id
    if (songId) {
      const getStream = async () => {
        try {
          const json = await post(
            prependApiUrl("marketplace/songs/get_stream"),
            {
              data: { song_id: songId },
            }
          )
          if (json.error) {
            return { error: json.error }
          } else {
            return json
          }
        } catch (err) {
          return { error: err }
        }
      }
      try {
        const streamUrl = await getStream()
        this.setState({ src: streamUrl }, this.handleSetPosition(seekPos))
      } catch (error) {
        console.error("Error fetching getStream URL:", error)
      }
    }
  }

  componentDidMount = () => {
    key("space", (e) => {
      e.preventDefault()
      if (!e.repeat) this.handleTogglePause()
    })

    key("left, up", (e) => {
      e.preventDefault()
      this.handlePrev()
    })

    key("right, down", (e) => {
      e.preventDefault()
      this.handleNext()
    })

    key("shift+., .", (e) => {
      e.preventDefault()
      this.handleSeekForward(15)
    })

    key("shift+,, ,", (e) => {
      e.preventDefault()
      this.handleSeekBackward(15)
    })

    key("f", (e) => {
      e.preventDefault()
      if (!e.repeat) this.handleToggleFavorite()
    })

    key("p", (e) => {
      e.preventDefault()
      this.handleAddToPlaylist()
    })

    if (this.props.audioFile) {
      this.setState((_, props) => ({
        src: props.audioFile.mp3Url(),
      }))
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      algoliaState,
      audioPlayer,
      currentUser,
      song,
      trackMixpanel,
      trackMarketplaceMixpanel,
      audioFile,
    } = this.props

    const {
      seekPos,
      audioFileId,
      parentSongId,
      childSongRank,
      playing,
    } = this.props.audioPlayer
    const prevAudioPlayer = prevProps.audioPlayer
    const prevSong = prevProps.song
    const prevPlaylist = prevProps.playlist
    const prevAudioFile = prevProps.audioFile
    const prevSeekPos = prevAudioPlayer.seekPos
    const prevPos = prevAudioPlayer.pos
    const prevAudioFileId = prevAudioPlayer.audioFileId

    if (!this.player) return null

    if (seekPos !== prevSeekPos) {
      this.player.seek(seekPos)
      this.props.playProgress(seekPos)
    }

    const songChanged =
      prevSong?.id !== song?.id ||
      prevAudioFile?.id !== audioFile?.id ||
      prevAudioFileId !== audioFileId

    if (songChanged) {
      const newSeekPos = this.state.resetPos ? 0 : seekPos
      this.setState({
        loadingAudioPeaks: true,
        peaks: null,
      })

      if (audioFile) {
        this.getPeaks(audioFile)
        this.setState({ src: audioFile.mp3Url() })
      } else if (song?.tuned_global_id) {
        this.setState({ src: null })
        this.getPeaks(song)
        this.fetchStreamUrl(newSeekPos)
      }
    }

    // If it was paused, track that they listened this far
    // If the audio file was changed while playing, track that they
    // listened this far. If it wasn't playing, it was tracked when
    // it was paused.
    const songAndAudio = prevSong && (prevAudioFile || prevSong.tuned_global_id)

    if (songAndAudio && prevAudioPlayer.playing && (!playing || songChanged)) {
      const trackingData = {
        "Play Duration": Math.round(prevPos),
        "Play Completed": songChanged,
        "Song ID": prevSong.id,
        "Song Title": prevSong.title,
        "Song Parent ID": parentSongId,
        "Recommendation Rank": childSongRank,
        "Artist ID": prevSong.primaryArtistId(),
        "Artist Name": prevSong.primaryArtistName(),
        "Playlist ID": get(prevPlaylist, "id", null),
        "Playlist Name": get(prevPlaylist, "name", null),
      }

      if (prevAudioFile && prevSong.type === "songs") {
        trackingData["Song Version ID"] = prevAudioFile.id
        trackingData["Song Version Description"] = prevAudioFile.description
      } else if (prevSong.type === "marketplace_songs") {
        trackingData["Content Type"] = prevSong.contentType()
      }

      const trackingFunction =
        prevSong.type === "marketplace_songs"
          ? trackMarketplaceMixpanel
          : trackMixpanel

      trackingFunction("Played Song", trackingData)
    }

    const userToken = currentUser.get("id")
    const queryID = algoliaState.get("queryID")
    const songId = song?.id || song?.get?.("id")
    const songType = song?.type

    if (songChanged && userToken && songId) {
      trackAlgolia(
        queryID ? "clickedObjectIDsAfterSearch" : "clickedObjectIDs",
        {
          userToken,
          eventName: "Song Played",
          index: currentSortIndexForUser(
            currentUser,
            algoliaState.get("sort"),
            songType
          ),
          ...(queryID && { queryID }),
          objectIDs: [songId],
          ...(queryID && { positions: [audioPlayer.get("radioIndex") + 1] }),
        }
      )
    }
  }

  componentWillUnmount = () =>
    key.unbind("space, left, right, up, down, f, p, shift+., shift+,, ., ,")

  handleAddToPlaylist = () => {
    if (!this.props.song) return

    this.props.addToPlaylist(this.props.song)
  }

  handleToggleFavorite = () => {
    const { song, currentUser, unfavorite, favorite } = this.props
    if (!song) return

    if (currentUser.favoritedSong(song.id)) {
      unfavorite(song)
    } else {
      favorite(song)
    }
  }

  handleNext = () => {
    const { audioPlayerCursor, next } = this.props
    const hasNext = audioPlayerCursor && audioPlayerCursor.get("next")
    if (hasNext) this.setState({ resetPos: true }, next)
  }

  handleOnEnd = () => {
    const { next, ended, audioPlayerCursor } = this.props
    const hasNext = audioPlayerCursor && audioPlayerCursor.get("next")

    const endedData = hasNext
      ? { ended: true }
      : { ended: true, playing: false }

    ended(endedData)
    if (hasNext) next()
  }

  handlePlay = () => {
    if (this.props.soundEffectIsPlaying) {
      this.props.pauseSoundEffectsPlayer()
    }

    setTimeout(this.step, 200)
  }

  handlePrev = () => {
    this.setState({ resetPos: true }, this.props.prev)
  }

  handleSeek = (e) => {
    const trackingFunction =
      this.props.song.type === "marketplace_songs"
        ? this.props.trackMarketplaceMixpanel
        : this.props.trackMixpanel

    trackingFunction("Clicked Element", {
      context: "Waveform",
    })
    if (!loggedIn()) {
      return this.props.openSignupModal("waveform")
    }
    this.props.seek(e)
  }

  handleToggleMute = () => {
    if (!this.state.mute && !this.props.audioPlayer.volume) {
      this.props.changeVolume(1)
    } else {
      this.setState((state) => ({ mute: !state.mute }))
    }
  }

  handleTogglePause = () => {
    this.props.togglePause()
  }

  handleVolumeChange = (value) => this.props.changeVolume(value / 100)

  handleSeekBackward = (sec) => {
    const { audioPlayer, audioFile, song, seek } = this.props
    const mediaItem = audioFile || song

    if (!mediaItem) return

    const newPos = audioPlayer.pos - sec

    if (newPos <= 0) {
      this.handlePrev()
    } else {
      seek(newPos)
    }
  }

  handleSeekForward = (sec) => {
    const { audioPlayer, audioFile, song, seek } = this.props
    const mediaItem = audioFile || song

    if (mediaItem) {
      const newPos = audioPlayer.pos + sec
      const duration = mediaItem?.duration

      if (newPos >= duration) {
        this.handleNext()
      } else {
        seek(newPos)
      }
    }
  }

  handleSetPosition = (pos) => {
    setTimeout(() => {
      this.player.seek(pos)
      this.props.playProgress(pos)
    }, 0)
    this.setState({ resetPos: false })
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const player = this.props.audioPlayer
    const nextPlayer = nextProps.audioPlayer
    const nextAudioFile = nextProps.audioFile

    if (player.audioFileId !== nextPlayer.audioFileId && nextAudioFile) {
      this.setState({ src: nextAudioFile.mp3Url() })
    }
  }

  getPeaks = (mediaItem) => {
    if (!mediaItem) return

    if (mediaItem.peakDataUrl && mediaItem.peakDataUrl()) {
      getRequest(mediaItem.peakDataUrl())
        .then((response) => {
          if (this.wrapper) {
            // Response can either be an array if the peak data was generated by transloadit
            // or an object with an array property if it was generated by carrierwave-audio-waveform
            const peaks =
              (Array.isArray(response) ? response : response.data) || null
            this.setState({ peaks, loadingAudioPeaks: false })
          }
        })
        .catch(() => {
          if (this.wrapper) {
            this.setState({ loadingAudioPeaks: false })
          }
        })
    } else {
      // Handle marketplace songs with waveform_data
      const peaks = mediaItem.waveform_data
      this.setState({ peaks, loadingAudioPeaks: false })
    }
  }

  step = () => {
    if (!this.props.audioPlayer.playing) return
    const howler = this.player?.howler
    const songReady = this.state.src && this.player.seek() > 0 // prevents UI Flashing due to async fetch
    if (howler && songReady) {
      this.props.playProgress(this.player.seek())
      setTimeout(this.step, 200)
    } else {
      // retry
      setTimeout(this.step, 200)
    }
  }

  render() {
    const { audioPlayer, audioFile, song, screenSize, className } = this.props

    if (!audioPlayer.playerVisible) {
      return null
    }

    const mediaItem = audioFile || song
    if (!mediaItem) return null

    return (
      <PageContentSection
        className={className}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
      >
        <ReactHowler
          ref={(instance) => (this.player = instance)}
          src={
            Array.isArray(this.state.src) ? this.state.src : [this.state.src]
          }
          playing={audioPlayer.playing}
          html5
          onEnd={this.handleOnEnd}
          onPlay={this.handlePlay}
          format={audioFile ? [audioFile.fileExtension] : ["aac"]}
          volume={audioPlayer.volume}
          mute={this.state.mute}
          preload={true}
        />
        {screenSize === MOBILE ? (
          <MobileView
            duration={parseInt(mediaItem.duration)}
            handleTogglePause={this.handleTogglePause}
            index={audioPlayer.radioIndex}
            isPlaying={audioPlayer.playing}
            storeKey={audioPlayer.listStoreKey}
            song={song}
          />
        ) : (
          <DesktopWrapper>
            <InfoSection song={song} />
            <RadioControls
              handlePrev={this.handlePrev}
              handleNext={this.handleNext}
              handleTogglePause={this.handleTogglePause}
              isPlaying={audioPlayer.playing}
              showRadioControls={!!audioPlayer.listStoreKey}
            />
            <WaveformSection
              duration={parseInt(mediaItem.duration)}
              formattedDuration={
                mediaItem.durationFormatted?.() || mediaItem.durationFormatted
              }
              handleSeek={this.handleSeek}
              peaks={this.state.peaks}
              pos={audioPlayer.pos}
            />
            <Volume
              handleToggleMute={this.handleToggleMute}
              handleVolumeChange={this.handleVolumeChange}
              mute={this.state.mute}
              volume={audioPlayer.volume}
            />
            <ActionSection song={song} />
          </DesktopWrapper>
        )}
      </PageContentSection>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  favorite: (song) => dispatch(favoritableActions.favorite(song)),
  addToPlaylist: (song) =>
    dispatch(modalActions.open("AddMediaToPlaylistModal", { record: song })),
  changeVolume: (volume) => dispatch(actions.changeVolume(volume)),
  ended: (data) => dispatch(actions.ended(data)),
  next: (noAutoPlay) => dispatch(actions.next(noAutoPlay)),
  openSignupModal: (actionType) =>
    dispatch(modalActions.open("SignUpModal", { action: actionType })),
  playProgress: (pos) => dispatch(actions.progress(pos)),
  prev: () => dispatch(actions.prev()),
  unfavorite: (song) => dispatch(favoritableActions.unfavorite(song)),
  seek: (seekPos) => dispatch(actions.seek(seekPos)),
  pause: () => dispatch(actions.pause()),
  resume: () => dispatch(actions.resume()),
  togglePause: () => dispatch(actions.togglePause()),
  pauseSoundEffectsPlayer: () =>
    dispatch(soundEffectsPlayerActions.togglePause()),
})

const mapStateToProps = (state) => ({
  algoliaState: selectAlgoliaListByStoreKey("songs")(state),
  audioFile: selectAudioPlayerAudioFile()(state),
  audioPlayer: selectAudioPlayer()(state),
  currentUser: selectCurrentUserRecord()(state),
  playlist: selectAudioPlayerPlaylist()(state),
  song: selectAudioPlayerSong()(state),
  soundEffectIsPlaying: selectSoundEffectsPlayerPlaying()(state),
  audioPlayerCursor: selectAudioPlayerCursor()(state),
})

AudioPlayer.propTypes = {
  addToPlaylist: PropTypes.func.isRequired,
  algoliaState: PropTypes.object,
  audioFile: PropTypes.oneOfType([
    PropTypes.instanceOf(AudioFileRecord),
    PropTypes.instanceOf(MarketplaceAudioFileRecord),
  ]),
  audioPlayer: PropTypes.instanceOf(AudioPlayerRecord),
  audioPlayerCursor: PropTypes.object,
  changeVolume: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentUser: PropTypes.instanceOf(UserRecord),
  ended: PropTypes.func.isRequired,
  favorite: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  openSignupModal: PropTypes.func,
  pauseSoundEffectsPlayer: PropTypes.func.isRequired,
  playProgress: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  screenSize: PropTypes.string,
  seek: PropTypes.func.isRequired,
  song: PropTypes.oneOfType([
    PropTypes.instanceOf(SongRecord),
    PropTypes.instanceOf(MarketplaceSongRecord),
  ]),
  soundEffectIsPlaying: PropTypes.bool,
  togglePause: PropTypes.func.isRequired,
  trackMixpanel: PropTypes.func,
  trackMarketplaceMixpanel: PropTypes.func,
  unfavorite: PropTypes.func.isRequired,
}

export default withScreenSize(
  withMarketplaceMixpanelTracking(
    withMixpanelTracking(
      connect(mapStateToProps, mapDispatchToProps)(AudioPlayer)
    )
  )
)
