/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import "babel-polyfill"

// Import all the third party stuff
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { applyRouterMiddleware, Router, browserHistory } from "react-router"
import { syncHistoryWithStore } from "react-router-redux"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useScroll } from "react-router-scroll"
import { IntlProvider } from "react-intl"
import determineLocale from "utils/determineLocale"
import "sanitize.css/sanitize.css"
import { QueryClient, QueryClientProvider } from "react-query"
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react"

const config = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  revision: process.env.COMMIT_HASH,
  enableUncaught: false,
}
const honeybadger = Honeybadger.configure(config)

Honeybadger.beforeNotify(function(notice) {
  if (
    notice &&
    /UnhandledPromiseRejectionWarning: Unspecified reason/.test(notice.message)
  ) {
    return false
  }
})

// Import root app
import App from "containers/App"

// Import selector for `syncHistoryWithStore`
import { makeSelectLocationState } from "containers/App/selectors"

// Load the favicon, the manifest.json file and the .htaccess file
import "!file-loader?name=[name].[ext]!./favicon.ico"
import "file-loader?name=[name].[ext]!./.htaccess"

// Load vendor files
import "!!style-loader!css-loader!../vendor/css/react-toggle.css"
import "!!style-loader!css-loader!../vendor/css/react-multi-email.css"
import "!!style-loader!css-loader!../vendor/css/bambooHR.css"

import configureStore from "./store"

// Import CSS reset and Global Styles
import GlobalStyles from "./global-styles"

// Import routes
import createRoutes from "./routes"
import withUTM from "./hocs/withUTM"

// Import context providers
import { DebugFlagContextProvider } from "./context/DebugFlagContext"

// FIXING SERVICE WORKER PROBLEMS

if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {}
const store = configureStore(initialState, browserHistory)

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
})

// Set up the router, wrapping all Routes in the App component
const rootRoute = {
  component: withUTM(App),
  childRoutes: createRoutes(store),
}

const MOUNT_NODE = document.getElementById("app")

const languages = {
  en: require("translations/en.json"),
  de: require("translations/de.json"),
}
const locale = determineLocale({
  supportedLocales: Object.keys(languages),
  defaultLocale: "en",
})

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

const queryClient = new QueryClient()

const render = () => {
  ReactDOM.render(
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <DebugFlagContextProvider>
        <IntlProvider locale={locale} messages={languages[locale]}>
          <QueryClientProvider client={queryClient}>
            <Elements stripe={stripePromise}>
              <Provider store={store}>
                <Router
                  history={history}
                  routes={rootRoute}
                  render={
                    // Scroll to top when going to a new page, imitating default browser
                    // behaviour
                    applyRouterMiddleware(useScroll())
                  }
                />
                <GlobalStyles />
              </Provider>
            </Elements>
          </QueryClientProvider>
        </IntlProvider>
      </DebugFlagContextProvider>
    </HoneybadgerErrorBoundary>,
    MOUNT_NODE
  )
}

if (window.Cypress) {
  window.store = store
}

render()
