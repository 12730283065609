import toString from "lodash/toString"
import { put, select, takeEvery } from "redux-saga/effects"
import { selectRecordById } from "ducks/api"
import { actions as uiActions } from "ducks/ui"
import { addToPlayHistory } from "utils/playHistory"

import {
  actions,
  types,
  selectAudioPlayerCursor,
  selectAudioPlayerList,
  selectAudioPlayer,
  selectAudioPlayerShuffled,
  selectAudioPlayerSongType,
} from "ducks/audioPlayer"

import {
  selectInfiniteListByStoreKey,
  actions as infiniteListActions,
} from "ducks/infiniteList"

import {
  selectCurrentDaaciId,
  actions as aiSongEditorActions,
} from "ducks/aiSongEditor"

export function* next() {
  try {
    const cursor = yield select(selectAudioPlayerCursor())
    const list = yield select(selectAudioPlayerList())
    const shuffled = yield select(selectAudioPlayerShuffled())
    // TODO: This will need to be updated when we have mixed songs lists
    const songType = yield select(selectAudioPlayerSongType())
    const listToPlay = shuffled ? list?.shuffledData : list?.data
    const songId = cursor && cursor.get("next")

    if (songId) {
      const song = yield select(selectRecordById(songType, songId))
      const radioIndex = listToPlay.indexOf(songId)
      const data = {
        audioFileId: toString(song.primary_audio_file_id),
        tunedGlobalId: song?.tuned_global_id,
        playing: true,
        pos: 0,
        radioIndex,
        songId,
        songType: songType,
      }
      yield put(actions.play(data))
    } else if (list && list.nextLink && !list.loading) {
      yield put(infiniteListActions.loadMore(list.storeKey, list.nextLink))
      yield put(actions.next())
    } else {
      yield put(actions.togglePause())
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchNext() {
  yield takeEvery(types.NEXT, next)
}

export function* prev() {
  try {
    const cursor = yield select(selectAudioPlayerCursor())
    const list = yield select(selectAudioPlayerList())
    const shuffled = yield select(selectAudioPlayerShuffled())
    // TODO: This will need to be updated when we have mixed songs lists
    const songType = yield select(selectAudioPlayerSongType())
    const listToPlay = shuffled ? list?.shuffledData : list?.data
    const songId = cursor && cursor.get("prev")

    if (songId) {
      const song = yield select(selectRecordById(songType, songId))
      const radioIndex = listToPlay.indexOf(songId)
      const data = {
        audioFileId: toString(song.primary_audio_file_id),
        tunedGlobalId: song?.tuned_global_id,
        playing: true,
        pos: 0,
        radioIndex,
        songId,
        songType: songType,
      }
      yield put(actions.play(data))
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchPrev() {
  yield takeEvery(types.PREV, prev)
}

export function* play() {
  try {
    const cursor = yield select(selectAudioPlayerCursor())
    const currentDaaciId = yield select(selectCurrentDaaciId())
    if (!!currentDaaciId) yield put(aiSongEditorActions.setDaaciId(""))

    if (cursor && !cursor.get("next")) {
      const list = yield select(selectAudioPlayerList())
      if (list && list.nextLink && !list.loading) {
        yield put(infiniteListActions.loadMore(list.storeKey, list.nextLink))
      }
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchPlay() {
  yield takeEvery(types.PLAY, play)
}

export function* progress(action) {
  try {
    const audioPlayer = yield select(selectAudioPlayer())
    if (audioPlayer.songType === "songs") {
      addToPlayHistory("songs", {
        audioFileId: audioPlayer.audioFileId,
        objectId: audioPlayer.songId,
        pos: action.pos,
      })
    } else if (audioPlayer.songType === "marketplace_songs") {
      if (audioPlayer.tunedGlobalId) {
        addToPlayHistory("marketplace_songs", {
          objectId: audioPlayer.tunedGlobalId,
          pos: action.pos,
        })
      } else {
        addToPlayHistory("marketplace_audio_files", {
          objectId: audioPlayer.audioFileId,
          pos: action.pos,
        })
      }
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchProgress() {
  yield takeEvery(types.PROGRESS, progress)
}

export function* shuffle(action) {
  try {
    const audioPlayer = yield select(selectAudioPlayer())
    const playerNotPlaying = !audioPlayer.audioFileId && !audioPlayer.songId
    const differentPlaylist = audioPlayer.listStoreKey !== action.data.storeKey

    if (playerNotPlaying || differentPlaylist) {
      const list = yield select(
        selectInfiniteListByStoreKey(action.data.storeKey)
      )
      const listToPlay = list.shuffledData
      const firstSongId = listToPlay.get(0)
      // TODO: This will need updated when we have mixed songs lists
      const firstSong = yield select(selectRecordById("songs", firstSongId))
      const radioIndex = listToPlay.indexOf(firstSongId)

      const data = {
        listStoreKey: action.data.storeKey,
        playlistId: action.data.playlistId,
        audioFileId: toString(firstSong.primary_audio_file_id),
        playing: true,
        playerVisible: true,
        pos: 0,
        radioIndex,
        songId: firstSongId,
        songType: firstSong.type,
        shuffled: true,
      }

      yield put(actions.play(data))
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchShuffle() {
  yield takeEvery(types.SHUFFLE, shuffle)
}

export default [watchNext, watchPlay, watchPrev, watchProgress, watchShuffle]
