import PropTypes from "prop-types"
import React, { memo } from "react"
import styled, { withTheme } from "styled-components"
import CanvasWaveform from "components/CanvasWaveform"
import { secondsToMMSS } from "utils/formatting"

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1;
  height: 100%;
  justify-content: space-between;
  max-width: 400px;
  padding: 25px 12px;
  position: relative;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl2}) {
    max-width: 700px;
  }
`

const Duration = styled.div`
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.secondary};
  user-select: none;
  width: 50px;

  @media (max-width: ${({ theme }) => theme.flexboxgrid.breakpoints.md}em) {
    display: none;
  }
`

const WaveformSection = ({
  duration,
  formattedDuration,
  handleSeek,
  peaks,
  pos,
  theme,
}) => {
  return (
    <Wrapper>
      <Duration className="pr-1">{secondsToMMSS(Math.round(pos))}</Duration>
      {peaks && (
        <CanvasWaveform
          peaks={peaks}
          height={40}
          pos={pos}
          duration={duration}
          onClick={handleSeek}
          progressColor={theme.colors.text.accent}
        />
      )}
      <Duration className="pl-1">{formattedDuration}</Duration>
    </Wrapper>
  )
}

WaveformSection.propTypes = {
  duration: PropTypes.number.isRequired,
  formattedDuration: PropTypes.string.isRequired,
  handleSeek: PropTypes.func.isRequired,
  peaks: PropTypes.array,
  pos: PropTypes.number.isRequired,
}

export default memo(withTheme(WaveformSection))
