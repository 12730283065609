import { css } from "styled-components"
import { color, layout, position, space, typography } from "styled-system"
import { focusStyles } from "containers/App/style-utils"

const buttonStyles = css`
  align-items: center;
  background: ${(props) => props.theme.colors.button.default.bg.default};
  border-radius: ${(props) => props.theme.radii.pill};
  border: ${(props) => props.theme.borders[0]}
    ${(props) => props.theme.colors.button.default.border.default};
  color: ${(props) => props.theme.colors.button.default.fg.default};
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  justify-content: center;
  letter-spacing: ${(props) => props.theme.letterSpacings[3]};
  line-height: ${(props) => props.theme.lineHeights.text.md};
  padding: ${(props) => props.theme.space[2]}
    ${(props) => props.theme.space[5]};
  text-decoration: none;
  transition: all ${(props) => props.theme.transitions.quick};
  user-select: none;

  &:hover {
    border-color: ${(props) => props.theme.colors.button.default.border.hover};
  }

  &:focus {
    ${focusStyles}
  }

  &:active {
    border-color: ${(props) =>
      props.theme.colors.button.default.border.default};
  }

  &:disabled {
    border-color: ${(props) =>
      props.theme.colors.button.default.border.disabled};
    color: ${(props) => props.theme.colors.button.default.fg.disabled};
    cursor: default;
  }

  /* Buttons with no text label get have horizontal padding */
  &.icon-only {
    padding: ${(props) => props.theme.space[2]};
  }

  &.stroke {
    color: ${(props) => props.theme.colors.button.primary.bg.default};
    border-color: ${(props) => props.theme.colors.button.primary.bg.default};
    background-color: ${(props) => props.theme.colors.background.default};
    &:hover {
      background-color: ${(props) =>
        props.theme.colors.button.primary.bg.default};
      color: ${(props) => props.theme.colors.white};
    }
  }

  &.stroke-pink {
    color: ${(props) => props.theme.colors.button.cta.bg.default};
    border-color: ${(props) => props.theme.colors.button.cta.bg.default};
    background-color: ${(props) => props.theme.colors.background.default};
    &:hover {
      background-color: ${(props) => props.theme.colors.button.cta.bg.default};
      color: ${(props) => props.theme.colors.white};
    }
  }

  &.stroke-blue {
    color: ${(props) => props.theme.colors.blue300};
    border-color: ${(props) => props.theme.colors.blue300};
    background-color: ${(props) => props.theme.colors.background.default};
    &:hover {
      background-color: ${(props) => props.theme.colors.blue300};
      color: ${(props) => props.theme.colors.white};
    }
  }

  &.cta {
    background: ${(props) => props.theme.colors.button.cta.bg.default};
    border-color: ${(props) => props.theme.colors.button.cta.bg.default};
    color: ${(props) => props.theme.colors.button.cta.fg.default};

    &:hover {
      background: ${(props) => props.theme.colors.button.cta.bg.hover};
      border-color: ${(props) => props.theme.colors.button.cta.bg.hover};
    }

    &:active {
      background: ${(props) => props.theme.colors.button.cta.bg.hover};
      border-color: ${(props) => props.theme.colors.button.cta.bg.hover};
    }

    &:disabled {
      background: ${(props) => props.theme.colors.button.cta.bg.default};
      border-color: ${(props) => props.theme.colors.button.cta.bg.default};
      color: ${(props) => props.theme.colors.button.cta.fg.default};
      opacity: ${(props) => props.theme.opacity.disabled};
    }
  }

  &.pinkJoy {
    background: ${(props) => props.theme.colors.button.pinkJoy.bg.default};
    border-color: ${(props) => props.theme.colors.button.pinkJoy.bg.default};
    color: ${(props) => props.theme.colors.button.pinkJoy.fg.default};

    &.selected {
      background: ${(props) => props.theme.colors.button.pinkJoy.bg.default};
      border-color: ${(props) => props.theme.colors.button.pinkJoy.bg.default};
      color: ${(props) => props.theme.colors.button.pinkJoy.fg.default};
      opacity: ${(props) => props.theme.opacity.disabled};
    }
    &:hover {
      background: ${(props) => props.theme.colors.button.pinkJoy.bg.hover};
      border-color: ${(props) => props.theme.colors.button.pinkJoy.bg.hover};
    }

    &:active {
      background: ${(props) => props.theme.colors.button.pinkJoy.bg.hover};
      border-color: ${(props) => props.theme.colors.button.pinkJoy.bg.hover};
    }

    &:disabled {
      background: ${(props) => props.theme.colors.button.pinkJoy.bg.default};
      border-color: ${(props) => props.theme.colors.button.pinkJoy.bg.default};
      color: ${(props) => props.theme.colors.button.pinkJoy.fg.default};
      opacity: ${(props) => props.theme.opacity.disabled};
    }
  }

  &.blue {
    background: ${(props) => props.theme.colors.blue300};
    border-color: ${(props) => props.theme.colors.blue300};
    color: ${(props) => props.theme.colors.button.pinkJoy.fg.default};

    &:hover {
      background: ${(props) => props.theme.colors.blue400};
      border-color: ${(props) => props.theme.colors.blue400};
    }
  }

  &.gray {
    background: ${(props) => props.theme.colors.gray500};
    border-color: ${(props) => props.theme.colors.gray500};
    color: ${(props) => props.theme.colors.button.pinkJoy.fg.default};

    &:hover {
      background: ${(props) => props.theme.colors.gray600};
      border-color: ${(props) => props.theme.colors.gray600};
    }
  }

  &.green {
    background: ${(props) => props.theme.colors.green300};
    border-color: ${(props) => props.theme.colors.green300};
    color: ${(props) => props.theme.colors.button.pinkJoy.fg.default};

    &:hover {
      background: ${(props) => props.theme.colors.green400};
      border-color: ${(props) => props.theme.colors.green400};
    }
  }

  &.black {
    ${(props) =>
      props.theme.mode === "dark"
        ? `color: ${props.theme.colors.black};
          background: ${props.theme.colors.button.cta.fg.default};
          border-color: ${props.theme.colors.button.cta.fg.default};`
        : `color: ${props.theme.colors.button.cta.fg.default};
        background: ${props.theme.colors.black};
        border-color: ${props.theme.colors.button.cta.fg.default};`}

    &:hover {
      background: ${(props) => props.theme.colors.gray600};
      border-color: ${(props) => props.theme.colors.gray600}};
    }
  }

  &.primary-border {
    border-color: ${(props) => props.theme.colors.text.primary}};
  }

  &.white {
    background: ${(props) => props.theme.colors.button.default.bg.default};
    border-color: ${(props) => props.theme.colors.white};

    &:hover {
      border-color: ${(props) => props.theme.colors.gray400}};
    }
  }

  &.mktbtn {
    ${(props) =>
      `color: ${props.theme.colors.button.cta.fg.default};
        background: ${props.theme.colors.black};
        border-color: ${props.theme.colors.button.cta.fg.default};`}

    &:hover {
      border-color: ${(props) => props.theme.colors.gray600}};
    }
  }

  &.primary {
    background: ${(props) => props.theme.colors.button.primary.bg.default};
    border-color: ${(props) =>
      props.theme.colors.button.primary.border.default};
    color: ${(props) => props.theme.colors.button.primary.fg.default};

    &.disabled {
      ${(props) =>
        props.theme.mode === "dark"
          ? `color: ${props.theme.colors.button.default.fg.disabled};`
          : ""}
      background: ${(props) => props.theme.colors.button.primary.bg.disabled};
      border-color: ${(props) =>
        props.theme.colors.button.primary.border.disabled};
      cursor: not-allowed;
      pointer-events: none;
    }

    &:not(:disabled) {
      &:hover {
        background: ${(props) => props.theme.colors.button.primary.bg.hover};
        border-color: ${(props) =>
          props.theme.colors.button.primary.border.hover};
      }
      &:active {
        background: ${(props) => props.theme.colors.button.primary.bg.default};
        border-color: ${(props) =>
          props.theme.colors.button.primary.border.default};
      }
    }

    &:disabled {
      color: ${({ theme }) =>
        theme.mode === "dark" ? theme.colors.gray500 : ""};
      background: ${({ theme }) =>
        theme.mode === "dark"
          ? theme.colors.gray600
          : theme.colors.button.primary.bg.disabled};
      border-color: ${(props) =>
        props.theme.colors.button.primary.border.disabled};
    }
  }

  &.subtle {
    background: ${(props) => props.theme.colors.button.subtle.bg.default};
    border-color: ${(props) => props.theme.colors.button.subtle.border.default};

    &:hover,
    &:focus {
      background: ${(props) => props.theme.colors.button.subtle.bg.hover};
      border-color: ${(props) => props.theme.colors.button.subtle.border.hover};
    }

    &:active {
      background: ${(props) => props.theme.colors.button.subtle.bg.active};
    }

    &:disabled {
      background: ${(props) => props.theme.colors.button.subtle.bg.disabled};
      color: ${(props) => props.theme.colors.button.subtle.fg.disabled};
    }
  }

  &.ghost {
    background: none;
    border: none;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.colors.button.subtle.bg.hover};
    }
  }

  &.danger {
    background: ${(props) => props.theme.colors.text.alert};
    border-color: ${(props) => props.theme.colors.text.alert};
    color: ${(props) => props.theme.colors.white};

    &:hover {
      opacity: 0.8;
    }
  }

  &.xs {
    font-size: ${(props) => props.theme.fontSizes.xs};
    padding: ${(props) => props.theme.space[2]}
      ${(props) => props.theme.space[4]};
    line-height: 1rem;

    &.icon-only {
      padding: ${(props) => props.theme.space[2]};
    }
  }

  &.sm {
    font-size: ${(props) => props.theme.fontSizes.sm};
    padding: ${(props) => props.theme.space[2]}
      ${(props) => props.theme.space[4]};
    line-height: 1rem;

    &.icon-only {
      padding: ${(props) => props.theme.space[2]};
    }
  }

  &.lg {
    padding: ${(props) => props.theme.space[4]}
      ${(props) => props.theme.space[6]};

    &.icon-only {
      padding: ${(props) => props.theme.space[4]};
    }
  }

  &.link {
    color: ${(props) => props.theme.colors.action.default};
  }

  &.link-secondary {
    color: ${(props) => props.theme.colors.text.primary};
  }

  &.link-subtle {
    color: ${(props) => props.theme.colors.text.secondary};
  }

  svg:not(:only-child) {
    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 6px;
    }
  }

  &.link,
  &.link-secondary,
  &.link-subtle,
  &.link-cancel {
    border: none;
    border-radius: 0;
    padding: 4px 0;
    text-decoration: underline;
    justify-content: initial;

    &:hover {
      color: ${(props) => props.theme.colors.action.default};
      text-decoration: ${(props) =>
        props.keepUnderline ? "underline" : "none"};
    }

    &:active {
      color: ${(props) => props.theme.colors.action.hover};
      background: none;
    }

    &.secondary {
      border-bottom: ${(props) => props.theme.borders[0]}
        ${(props) => props.theme.colors.text.secondary};
      border-radius: 0;
      color: ${(props) => props.theme.colors.text.secondary};
    }

    &.no-active-state {
      &:active {
        color: ${(props) => props.theme.colors.text.secondary};
        background: none;
      }
    }

    svg:not(:only-child) {
      &:first-child {
        margin-right: 3px;
      }

      &:last-child {
        margin-left: 3px;
      }
    }
  }

  &.no-underline {
    text-decoration: none;
    border-bottom: unset;
    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
    }
  }

  &.link-cancel {
    color: ${(props) => props.theme.colors.text.secondary};

    &:hover,
    &:active {
      color: ${(props) => props.theme.colors.text.alert};
    }
  }

  &.link-pinkJoy {
    border: none;
    color: ${(props) => props.theme.colors.brand.pinkJoy};

    & svg {
      fill: ${(props) => props.theme.colors.brand.pinkJoy};
    }
  }

  &.license-flow {
    border-radius: ${(props) => props.theme.radii.rounder};
    background-color: ${(props) => props.theme.colors.background.primary};
    text-align: start;
    display: block;
    padding: ${(props) => props.theme.space[4]};
  }

  ${color}
  ${layout}
  ${space}
  ${typography}
  ${position}
`

export default buttonStyles
