import { List } from "immutable"
import isArray from "lodash/isArray"
import get from "lodash/get"
import {
  AccountInvitation,
  AccountUser,
  Artist,
  ArtistUi,
  AudioFile,
  MarketplaceArtist,
  MarketplaceAudioFile,
  MarketplacePlaylist,
  MarketplaceSale,
  MarketplaceSaleItem,
  MarketplaceSong,
  Playlist,
  PlaylistCategory,
  PlaylistCategoryPlaylist,
  PlaylistUi,
  Project,
  ProjectItem,
  Referral,
  Sale,
  Song,
  SoundEffect,
  Tag,
  TagUi,
  UserNotification,
  User,
  Video,
  VideoEncoding,
} from "./index"

export const makeRecord = (data) => ({
  relationships: data.relationships,
  id: data.id,
  type: data.type,
  ...data.attributes,
})

export const makeRecordWithIncluded = (data, included) => ({
  included,
  relationships: data.relationships,
  id: data.id,
  type: data.type,
  ...data.attributes,
})

export const createSaleRecord = (data, included) => {
  if (data.type === "sales") {
    return new Sale(makeRecordWithIncluded(data, included))
  }
}

export const createRecord = (data) => {
  if (!data.type) {
    console.error("BRAHHH we didn't find the data type", data)
    return null
  }
  switch (data.type) {
    case "artists":
      return new Artist(makeRecord(data))
    case "audio_files":
      return new AudioFile(makeRecord(data))
    case "marketplace_artists":
      return new MarketplaceArtist(makeRecord(data))
    case "marketplace_audio_files":
      return new MarketplaceAudioFile(makeRecord(data))
    case "marketplace_playlists":
      return new MarketplacePlaylist(makeRecord(data))
    case "marketplace_songs":
      return new MarketplaceSong(makeRecord(data))
    case "marketplace_sales":
      return new MarketplaceSale(makeRecord(data))
    case "marketplace_sale_items":
      return new MarketplaceSaleItem(makeRecord(data))
    case "playlists":
      return new Playlist(makeRecord(data))
    case "playlist_categories":
      return new PlaylistCategory(makeRecord(data))
    case "playlist_category_playlists":
      return new PlaylistCategoryPlaylist(makeRecord(data))
    case "project_items":
      return new ProjectItem(makeRecord(data))
    case "projects":
      return new Project(makeRecord(data))
    case "referrals":
      return new Referral(makeRecord(data))
    case "sales":
      return new Sale(makeRecord(data))
    case "songs":
      return new Song(makeRecord(data))
    case "sound_effects":
      return new SoundEffect(makeRecord(data))
    case "tags":
      return new Tag(makeRecord(data))
    case "account_invitation":
      return new AccountInvitation(makeRecord(data))
    case "account_users":
      return new AccountUser(makeRecord(data))
    case "users":
      return new User(makeRecord(data))
    case "user_notifications":
      return new UserNotification(makeRecord(data))
    case "videos":
    case "video":
      return new Video(makeRecord(data))
    case "video_encoding":
    case "video_encodings":
      return new VideoEncoding(makeRecord(data))
    case "sessions":
      return null
    default:
      console.warn(
        `${data.type} does not have a defined immutable record creator function`
      )
      return null
  }
}

export const createSearchFilterRecord = (data) => {
  if (!data.type) {
    console.error("BRAHHH find the data type", data)
    return null
  }
  switch (data.type) {
    case "artists":
      return new ArtistUi(makeRecord(data))
    case "curated_playlists":
      return new PlaylistUi(makeRecord(data))
    case "tags":
      return new TagUi(makeRecord(data))
    default:
      console.error(
        `${data.type} does not have a defined immutable record creator function`
      )
      return null
  }
}

export const createRecords = (data) => {
  if (isArray(data)) {
    return data.map((d) => createRecord(d))
  }
  return createRecord(data)
}

export const createRecordsFromResponse = (response) => {
  const record = createRecords(get(response, "data", []))
  const included = createRecords(get(response, "included", []))
  const recordForSuccess = isArray(record) ? record : [record]
  const includedForSuccess = isArray(included) ? included : [included]
  return [...recordForSuccess, ...includedForSuccess]
}

export const createSearchFilterRecords = (data) => {
  if (isArray(data)) {
    return data.map((d) => createSearchFilterRecord(d))
  }
  return createSearchFilterRecord(data)
}

export const getRecordType = (record) =>
  record.typeOfPlaylist ? record.typeOfPlaylist : record.type

export const createSearchFilterRecordsFromResponse = (response) => {
  const record = createSearchFilterRecords(response)
  const recordAsArray = isArray(record) ? record : [record]

  const recordForSuccess = recordAsArray.filter((value) => {
    if (value.type === "curated_playlists") {
      return (
        [
          "Atmosphere",
          "Cinematic",
          "Foley",
          "Human",
          "Humans",
          "Transportation",
        ].indexOf(value.name) < 0
      )
    }

    return true
  })

  return new List(recordForSuccess)
}

export const createRecordResponse = (response) => {
  const record = createRecords(get(response, "data", []))
  return record
}

export const createUserRecordsFromSignInResponse = (response) => {
  const records = createRecords(get(response, "included", []))
  return [...records]
}

export const createUserRecordFromSignInResponse = (response) => {
  const included = response.included || []
  let resource = null
  included.forEach((includedResource) => {
    if (includedResource.type === "users") {
      resource = includedResource
    }
  })
  return createRecords(resource)
}
