import PropTypes from "prop-types"
import React, { useState, memo } from "react"
import styled, { withTheme, css } from "styled-components"
import classNames from "classnames"
import FavoriteSong from "components/SongActions/FavoriteSong"
import SongActions from "components/MarketplaceSong/components/SongActions"
import MorePopover from "./MorePopover"
import MarketplaceMorePopover from "./MarketplaceMorePopover"
import CollectionPopover from "./CollectionPopover"
import Icon from "components/Icon"
import useCurrentUser from "hooks/useCurrentUser"

import {
  ICON_SS_HEART_EMPTY,
  ICON_SS_HEART_FULL,
  ICON_SS_ADD,
  ICON_SS_ELLIPSIS,
  ICON_SS_CLOSE,
} from "components/Icon/constants"

import {
  Song as SongRecord,
  MarketplaceSong as MarketplaceSongRecord,
} from "records"

const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.space[7]};
  position: relative;
`

const heartIconStyles = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 33.5px;
  cursor: pointer;
  position: relative;

  &:hover {
    &:after {
      content: "Favorite";
      position: absolute;
      bottom: 2px;
      left: -6px;
      white-space: nowrap;
      font-size: 10px;
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  &.favorited {
    &:hover {
      &:after {
        content: "Unfavorite";
        left: -12px;
      }
    }
  }
`

const HeartIconWrapper = styled(FavoriteSong)`
  ${heartIconStyles}
`

const MarketplaceHeartIconWrapper = styled(SongActions)`
  ${heartIconStyles}
`

const CollectionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  padding-right: 33.5px;

  &:hover {
    &:after {
      content: "Add To Collection";
      position: absolute;
      bottom: 2px;
      left: -26px;
      white-space: nowrap;
      font-size: 10px;
      color: ${({ theme }) => theme.colors.text.secondary};
    }

    &.collectionPopoverVisible {
      &:after {
        content: "Close";
        left: -1px;
      }
    }
  }
`

const MoreWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  margin-left: ${({ market, theme }) => (market ? theme.space[4] : 0)};

  &:hover {
    &:after {
      content: "More";
      position: absolute;
      bottom: 2px;
      left: -1px;
      white-space: nowrap;
      font-size: 10px;
      color: ${({ theme }) => theme.colors.text.secondary};
    }

    &.morePopoverVisible {
      &:after {
        content: "Close";
      }
    }
  }
`

const ActionSection = ({ song, theme }) => {
  const { currentUser } = useCurrentUser()
  const marketSong = song.type === "marketplace_songs"
  const MorePopoverComponent = marketSong ? MarketplaceMorePopover : MorePopover

  const HeartWrapperComponent = marketSong
    ? MarketplaceHeartIconWrapper
    : HeartIconWrapper

  const favoritedByUser = marketSong
    ? currentUser.favoritedMarketplaceSong(song.id)
    : currentUser.favoritedSong(song.id)

  const [morePopoverVisible, setMorePopoverVisible] = useState(false)

  const [collectionPopoverVisible, setCollectionPopoverVisible] = useState(
    false
  )

  const handleToggleMorePopover = (e) => {
    e.preventDefault()
    if (!morePopoverVisible) {
      setMorePopoverVisible(true)
      setTimeout(() => {
        window.addEventListener("click", function onClick() {
          setMorePopoverVisible(false)
          window.removeEventListener("click", onClick)
        })
      })
    }
  }

  const handleToggleCollectionPopover = (e) => {
    e.preventDefault()
    if (!collectionPopoverVisible) {
      setCollectionPopoverVisible(true)
      setTimeout(() => {
        window.addEventListener("click", function onClick() {
          setCollectionPopoverVisible(false)
          window.removeEventListener("click", onClick)
        })
      })
    }
  }

  return (
    <ActionWrapper>
      <HeartWrapperComponent
        className={classNames({ favorited: favoritedByUser })}
        song={song}
      >
        <Icon
          color={favoritedByUser ? theme.colors.text.accent : undefined}
          icon={favoritedByUser ? ICON_SS_HEART_FULL : ICON_SS_HEART_EMPTY}
          size={22}
        />
      </HeartWrapperComponent>

      {!marketSong && (
        <>
          <CollectionWrapper
            className={classNames({
              collectionPopoverVisible,
            })}
            onClick={handleToggleCollectionPopover}
          >
            <Icon icon={ICON_SS_ADD} size={22} />
          </CollectionWrapper>

          {collectionPopoverVisible && (
            <CollectionPopover
              song={song}
              currentUser={currentUser}
              closeMorePopover={() => setCollectionPopoverVisible(false)}
            />
          )}
        </>
      )}

      <MoreWrapper
        onClick={handleToggleMorePopover}
        className={classNames({
          morePopoverVisible,
        })}
        market={marketSong}
      >
        <Icon
          icon={morePopoverVisible ? ICON_SS_CLOSE : ICON_SS_ELLIPSIS}
          size={22}
        />
      </MoreWrapper>

      {morePopoverVisible && (
        <MorePopoverComponent
          song={song}
          currentUser={currentUser}
          closeMorePopover={() => setMorePopoverVisible(false)}
        />
      )}
    </ActionWrapper>
  )
}

ActionSection.propTypes = {
  song: PropTypes.oneOfType([
    PropTypes.instanceOf(SongRecord),
    PropTypes.instanceOf(MarketplaceSongRecord),
  ]).isRequired,
}

export default memo(withTheme(ActionSection))
