import { fromJS } from "immutable"
import { createAction } from "./utils"

export const types = {
  BUY_SONG_LICENSE: "soundstripe/interactions/BUY_SONG_LICENSE",
  BUY_SONG_AFTTER_PREVIEW_LICENSING_OPTIONS:
    "soundstripe/interactions/BUY_SONG_AFTTER_PREVIEW_LICENSING_OPTIONS",
}

export const actions = {
  buySongLicense: createAction(
    types.BUY_SONG_LICENSE,
    "song",
    "featuredBuying"
  ),
  buySongAfterPreviewLicensingOptions: createAction(
    types.BUY_SONG_AFTTER_PREVIEW_LICENSING_OPTIONS,
    "song",
    "simplifiedPreviewDownloads"
  ),
}

const initialState = fromJS({})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
