import {
  spawn,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects"
import { _delete, post, patch } from "utils/request"
import { types } from "ducks/playlistable"
import { actions as uiActions } from "ducks/ui"
import { actions as modalActions } from "ducks/modal"
import { actions as apiActions } from "ducks/api"
import {
  selectInfiniteListByStoreKey,
  actions as infiniteListActions,
} from "ducks/infiniteList"
import { actions as selectableActions } from "ducks/selectable"

export function* addToPlaylist(action) {
  try {
    const playlist = action.playlist
    const playlistable = action.playlistable
    const requestURL = `${process.env.API_URL}/playlists/${playlist.id}/playlist_items`

    const data = {
      data: {
        attributes: {
          playlistable_id: playlistable.id || playlistable.objectID,
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }

    yield call(post, requestURL, options)
    yield put(modalActions.close())
    if (action.onSuccess) action.onSuccess()
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(modalActions.close())
  }
}

export function* watchAddToPlaylist() {
  yield takeLatest(types.ADD_TO_PLAYLIST, addToPlaylist)
}

export function* updatePlaylistablePosition(action) {
  try {
    const { playlistableId, playlist, index } = action
    const requestURL = `${process.env.API_URL}/playlists/${playlist.id}/playlist_items/${playlistableId}`

    const data = {
      data: {
        attributes: {
          playlistable_id: playlistableId,
          position: index,
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    yield call(patch, requestURL, options)
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchUpdatePlaylistablePosition() {
  yield takeEvery(
    types.UPDATE_PLAYLISTABLE_POSITION,
    updatePlaylistablePosition
  )
}

export function* removeFromPlaylist(action) {
  try {
    const { playlist, playlistable, storeKey } = action
    const infStoreKey =
      storeKey || `private_playlist_${playlistable.type}_${playlist.id}`
    const infList = yield select(selectInfiniteListByStoreKey(infStoreKey))

    if (infList) {
      const playlistableIndex = infList.data.indexOf(playlistable.id)
      const newData = infList.data.delete(playlistableIndex)
      yield put(infiniteListActions.update(infStoreKey, "data", newData))
    }
    const requestURL = `${process.env.API_URL}/playlists/${playlist.id}/playlist_items/${playlistable.id}`
    const data = {
      data: {
        attributes: {
          playlistable_id: playlistable.id.toString(),
        },
      },
    }
    const options = {
      data: JSON.stringify(data),
    }
    yield call(_delete, requestURL, options)
    yield put(apiActions.readEndpoint(`/playlists/${playlist.id}`))
    if (playlistable?.relationships?.get("audio_files")) {
      yield spawn(
        removeMultiSelectable,
        playlistable.relationships
          .get("audio_files")
          .map((audioFile) => audioFile.id)
          .toArray()
      )
    } else {
      yield put(selectableActions.remove(playlistable.id))
    }
  } catch (err) {
    yield put(uiActions.setError(err))
  }
}

export function* watchRemoveFromPlaylist() {
  yield takeEvery(types.REMOVE_FROM_PLAYLIST, removeFromPlaylist)
}

function* removeMultiSelectable(ids) {
  for (const id of ids) {
    yield put(selectableActions.remove(id))
  }
}

export default [
  watchAddToPlaylist,
  watchUpdatePlaylistablePosition,
  watchRemoveFromPlaylist,
]
