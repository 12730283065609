import useFlag from "hooks/useFlag"

export default () => {
  const { enabled: flagFullyEnabled, loading: flagLoading } = useFlag(
    "metadata_slideout"
  )

  return {
    enabled: flagFullyEnabled,
    loading: flagLoading,
  }
}
