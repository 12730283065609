import Immutable from "immutable"

const UserNotificationRecord = Immutable.Record(
  {
    id: null,
    type: "user_notifications",
    title: "",
    message: "",
    read_at: null,
    archived_at: null,
    created_at: null,
    published_at: null,
    entry_id: null,
    tag: "",
    url: "",
  },
  "UserNotification"
)

class UserNotification extends UserNotificationRecord {
  constructor(args = {}) {
    super(args)
  }
}

export default UserNotification
