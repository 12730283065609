import { select, put, call, takeEvery, takeLatest } from "redux-saga/effects"
import { types, actions as subscriptionActions } from "ducks/subscription"
import { prependApiUrl, get, post } from "utils/request"
import { actions as uiActions } from "ducks/ui"
import { actions as cookieActions } from "middlewares/cookies"
import { UTM_PARAMS } from "utils/tracking"
import {
  actions as currentUserActions,
  selectCurrentUserRecord,
} from "ducks/currentUser"

export function* create({ checkoutAttributes }) {
  try {
    const utmCookies = yield put(cookieActions.getCookies(UTM_PARAMS))
    const currentUser = yield select(selectCurrentUserRecord())
    const paymentType = checkoutAttributes.payment_type
    delete checkoutAttributes.payment_type

    const requestUrl = prependApiUrl(`users/${currentUser.id}/subscriptions`)

    const data = {
      data: {
        type: "subscriptions",
        attributes: checkoutAttributes,
        utm_cookies: utmCookies,
      },
    }

    if (!!paymentType) data.data.payment_type = paymentType

    // reponse is the current user, just a quirk of our API
    const response = yield call(post, requestUrl, { data })

    yield put(
      subscriptionActions.createSuccess(
        response.data.attributes.subscription,
        response
      )
    )
    yield put(currentUserActions.load())
  } catch (err) {
    yield put(subscriptionActions.createFailed(err))
    yield put(uiActions.setError(err))
  }
}

export function* watchCreate() {
  yield takeLatest(types.CREATE, create)
}

export function* generateRenewalEstimate({ subscription }) {
  try {
    if (!subscription) return

    const url = prependApiUrl(
      `subscriptions/${subscription.id}/estimates/renewal_estimate`
    )
    const { response } = yield call(get, url)
    yield put(
      subscriptionActions.generateRenewalEstimateSuccess(response.estimate)
    )
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(subscriptionActions.generateRenewalEstimateFailed(err))
  }
}

export function* watchGenerateRenewalEstimate() {
  yield takeEvery(types.GENERATE_RENEWAL_ESTIMATE, generateRenewalEstimate)
}

export function* generateNewSubscriptionPlanEstimate({
  planId,
  couponCode,
  billingAddress,
}) {
  try {
    const user = yield select(selectCurrentUserRecord())

    const url = prependApiUrl(`users/${user.id}/subscription_estimates`)
    const data = {
      data: {
        attributes: {
          plan_id: planId,
          coupon_code: couponCode,
          billing_address: billingAddress || user.billing_address,
        },
      },
    }

    const { response } = yield call(post, url, { data })

    yield put(
      subscriptionActions.generatePlanEstimateSuccess(planId, response.estimate)
    )
  } catch (err) {
    yield put(subscriptionActions.generatePlanEstimateFailed(planId, err))
  }
}

export function* generateUpgradePlanEstimate({ planId, couponCode }) {
  try {
    const user = yield select(selectCurrentUserRecord())

    const url = prependApiUrl(`subscriptions/${user.subscription.id}/estimates`)
    const data = {
      data: {
        attributes: {
          plan_id: planId,
          coupon_code: couponCode,
          force_term_reset: true,
        },
      },
    }

    const { response } = yield call(post, url, { data })

    yield put(
      subscriptionActions.generatePlanEstimateSuccess(planId, response.estimate)
    )
  } catch (err) {
    yield put(subscriptionActions.generatePlanEstimateFailed(planId, err))
  }
}

export function* generatePlanEstimate(action) {
  const { planId } = action

  try {
    if (!planId) return

    const user = yield select(selectCurrentUserRecord())

    if (!user.subscription)
      yield call(generateNewSubscriptionPlanEstimate, action)
    else yield call(generateUpgradePlanEstimate, action)
  } catch (err) {
    yield put(subscriptionActions.generatePlanEstimateFailed(planId, err))
  }
}

export function* watchGeneratePlanEstimate() {
  yield takeEvery(types.GENERATE_PLAN_ESTIMATE, generatePlanEstimate)
}

export default [
  watchCreate,
  watchGenerateRenewalEstimate,
  watchGeneratePlanEstimate,
]
