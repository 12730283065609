import { useDispatch } from "react-redux"
import { actions as trackingActions } from "ducks/tracking"

const useMixpanelTracking = () => {
  const dispatch = useDispatch()

  const trackMixpanel = (eventName, eventProperties) => {
    dispatch(
      trackingActions.trackMixpanel(eventName, {
        ...eventProperties,
      })
    )
  }

  return {
    trackMixpanel,
  }
}

export default useMixpanelTracking
