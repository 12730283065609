import Immutable from "immutable"
import { createRelationships } from "records/Relationship"
import _toString from "lodash/toString"

const MarketplaceSaleItemRecord = Immutable.Record(
  {
    id: null,
    type: "marketplace_sale_items",
    created_at: null,
    orfium_license_code: "",
    download_count: 0,
    license_agreement_download_url: null,
    agreed_to_eula: null,
    cue_sheet_download_url: null,
    copyright_information_download_url: null,
    distributions: null,
    marketplace_sale_id: "",
    project_name: "",
    business_name: "",
    license_type: "",
    sellable_id: "",
    sellable_type: "",

    relationships: {
      sellable: {},
      marketplace_sale: {},
    },
  },
  "MarketplaceSaleItem"
)

const relationshipTypes = {
  sellable: ["marketplace_songs"],
  marketplace_sale: ["marketplace_sales"],
}

class MarketplaceSaleItem extends MarketplaceSaleItemRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        relationships: createRelationships(
          args.relationships,
          relationshipTypes
        ),
      })
    )
  }

  sellableId = () => _toString(this.sellable_id)
  saleId = () => _toString(this.marketplace_sale_id)

  hasContentIdAccess = () => {
    if (this.isStockLicense()) {
      return ["Personal", "Digital"].includes(this.license_type)
    } else {
      return !!this.distributions?.find((d) => d.key === "web_social")
    }
  }

  isStockLicense = () => {
    return !!this.license_type
  }

  isStockPersonal = () => {
    return this.license_type === "Personal"
  }

  contentType() {
    return this.isStockLicense() ? "Production" : "Popular"
  }
}

export default MarketplaceSaleItem
