import Cookie from "js-cookie"
import { loggedIn } from "utils/authentication"
import { debugFlag } from "utils/debugFlag"
import { Userpilot } from "userpilot"
import Honeybadger from "@honeybadger-io/js"
import { sharedStableId } from "utils/statsig/stable-id"

export const UTM_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "utm_id",
]

export const DISABLE_TRACKING_COOKIE = "soundstripe-disable-tracking"
export const STATSIG_STABLE_ID_COOKIE = "soundstripe.statsig.shared_stable_id"

const disableTracking = Cookie.get(DISABLE_TRACKING_COOKIE) === "true"

export const getMixpanelDeviceId = () => {
  const mixpanelCookie = Cookie.get(`mp_${process.env.MIXPANEL_TOKEN}_mixpanel`)
  if (mixpanelCookie) {
    try {
      const cookieData = JSON.parse(decodeURIComponent(mixpanelCookie))
      return cookieData["$device_id"]
    } catch (err) {
      Honeybadger.notify(err)
      console.error("Error parsing Mixpanel cookie:", err)
    }
  }
  return null
}

export const getStatsigStableId = () => {
  const stableId = sharedStableId()
  if (stableId) {
    return stableId
  } else {
    return null
  }
}

export const identifyMixpanelUser = (userId) => {
  if (debugFlag("DEBUG_TRACKING")) {
    const css = "background: #222; color: #bada55; padding: 12px;"
    console.info(`%cMIXPANEL EVENT: IDENTIFY`, css, userId)
  }
  onTrackingLoaded(() => {
    mixpanel.identify(userId)
  })
}

export const updateMixpanelSuperProperties = (user) => {
  if (disableTracking) {
    return false
  }
  const profile = {}

  profile["Current Account ID"] = user.account_id
  profile["Current Team Role"] =
    user.subscription?.isEnterprise() || user.subscription?.isTeam()
      ? user.role
      : null
  profile["Current Plan ID"] = user.subscription
    ? user.subscription.plan_id
    : "None"
  profile["Current Plan Name"] = user.subscription
    ? user.subscription.plan_name
    : "None"
  profile["Current Subscription ID"] = user.subscription
    ? user.subscription.id
    : null
  profile["Current Subscription Status"] = user.subscription
    ? user.subscription.status
    : user.free_account
    ? "None"
    : "cancelled"
  profile["Free Account"] = user.free_account
  profile["Licensed Songs Count"] = user.sales_count
  profile["Single Song License Count"] = user.single_song_sales_count
  profile["Referring User ID"] = user.referring_user_id
  profile["Frame.io"] = user.frameio_account_id !== null
  profile["Twitch"] = user.twitch_account
  profile["Statsig Stable ID"] = getStatsigStableId()

  onTrackingLoaded(() => {
    mixpanel.register(profile)
  })
}

export const updateMixpanelPeopleProperties = (user) => {
  if (disableTracking) {
    return false
  }

  const profile = {}
  const d = new Date()

  if (user) {
    profile["Created"] = d.toISOString()
    profile["Account ID"] = user.account_id
    profile["Team Role"] =
      user.subscription?.isEnterprise() || user.subscription?.isTeam()
        ? user.role
        : null
    profile["ID"] = user.id
    profile["$email"] = user.email
    profile["$first_name"] = user.first_name
    profile["$last_name"] = user.last_name
    profile["$name"] = user.name()
    profile["Company Name"] = user.company_name
    profile["Entity Type"] = user.entity_type
    profile["Entity Other"] = user.entity_other
    profile["Entity Size"] = user.entity_size
    profile["Free Account"] = user.free_account
    profile["Industries"] = user.industries
    profile["Frame.io"] = user.frameio_account_id !== null
    profile["Twitch"] = user.twitch_account
    profile["Enabled Email Notifications"] = user.email_notifications_enabled
    profile["Pricing Funnel Key"] = user.pricing_funnel_key
    profile["Plan Name"] = user.subscription
      ? user.subscription.plan_name
      : "None"
    profile["Subscription Status"] = user.subscription
      ? user.subscription.status
      : "None"
    profile["Licensed Songs Count"] = user.sales_count
    profile["Single Song License Count"] = user.single_song_sales_count
    profile["Statsig Stable ID"] = getStatsigStableId()
  }

  onTrackingLoaded(() => {
    mixpanel.people.set(profile)
  })
}

export const updateMixpanelPeopleAttribute = (attribute, value) => {
  if (disableTracking) {
    return false
  }
  onTrackingLoaded(() => {
    const profile = {}
    profile[attribute] = value

    mixpanel.people.set(profile)
  })
}

export const incrementMixpanelPeopleAttribute = (attribute) => {
  if (disableTracking) {
    return false
  }
  onTrackingLoaded(() => {
    mixpanel.people.increment(attribute)
  })
}

// Waits until Mixpanel and maybe Statsig are loaded to execute the tracking function
export const onTrackingLoaded = (fn, loadAttempts = 0, maxAttempts = 70) => {
  try {
    if (window.trackingLoaded && window.statsigLoaded) {
      // Wait for Mixpanel and Statsig to load
      fn()
    } else if (loadAttempts > maxAttempts) {
      if (!window.trackingLoaded) {
        console.error("Mixpanel failed to load!")
      }
      if (!window.statsigLoaded) {
        console.error("Statsig failed to load!")
      }
      if (window.trackingLoaded) {
        // If Mixpanel is loaded, but not Statsig, run the tracking function anyway
        fn()
      }
    } else {
      setTimeout(() => onTrackingLoaded(fn, ++loadAttempts), 100)
    }
  } catch (err) {
    console.error("Tracking error:", err.message)
  }
}

export const trackMixpanel = (event, properties = {}) => {
  if (debugFlag("DEBUG_TRACKING")) {
    const css = "background: #222; color: #bada55; padding: 12px;"
    console.info(`%cMIXPANEL EVENT: ${event}`, css, properties)
  }
  if (disableTracking) {
    return false
  }

  const d = new Date()

  onTrackingLoaded(() => {
    properties["Event Created"] = d.toISOString()
    properties["Event Host"] = `${process.env.BASE_APP_URL}`
    properties["Current URL"] = window.location.href
    properties["Signed In"] = loggedIn()
    properties["$device_id"] = getMixpanelDeviceId()
    properties["Statsig Stable ID"] = getStatsigStableId()

    UTM_PARAMS.forEach((utm) => {
      properties[utm] = Cookie.get(utm)
    })

    mixpanel.track(event, properties)
  })
}

const aa = require("search-insights")

aa("init", {
  appId: process.env.ALGOLIA_APP_ID,
  apiKey: process.env.ALGOLIA_API_KEY,
})

export const trackAlgolia = (event, properties) => {
  if (debugFlag("DEBUG_TRACKING")) {
    const css = "background: #222; color: #abdbe3; padding: 12px;"
    console.info(`%cALGOLIA EVENT: ${event}`, css, properties)
  }
  aa(event, properties)
}

export const trackUserpilot = (eventName, eventProperties = {}) => {
  Userpilot.track(eventName, eventProperties)
}
