import React from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import styled, { withTheme } from "styled-components"
import PropTypes from "prop-types"
import Slider from "rc-slider"
import { MarketplaceSong as MarketplaceSongRecord } from "records"
import { secondsToMMSS } from "utils/formatting"
import {
  AudioPlayerRecord,
  actions as audioPlayerActions,
  selectAudioPlayer,
  selectAudioPlayerSong,
} from "ducks/audioPlayer"
import { actions as modalActions } from "ducks/modal"
import Box from "components/Box"
import withMixpanelTracking from "hocs/withMixpanelTracking"

const AudioBarWrapper = styled.div`
  align-items: center;
  display: flex;
`

const Time = styled.div`
  color: ${(props) => props.theme.colors.text.secondary};
  font-size: ${(props) => props.theme.fontSizes.xs};
  line-height: 1rem;
`

const SLIDER_MAX = 1000

class MarketplaceAudioProgressBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tracking: false,
    }
  }

  handleSliderChange = (value) => {
    const { song, onChange, seek } = this.props
    this.props.trackMixpanel("Clicked Element", {
      context: "Waveform",
    })
    if (onChange) {
      onChange(value)
    } else {
      const percentage = value / SLIDER_MAX
      const newPos = percentage * song.duration
      seek(newPos)
    }
  }

  value = () => {
    const { song, audioPlayer } = this.props
    const percentageComplete = audioPlayer.pos / song.duration
    const value = Number(SLIDER_MAX * percentageComplete).toFixed(2) || 0
    return value === "NaN" ? 0 : value
  }

  duration = () => {
    const { song } = this.props
    return song.durationFormatted() === "NaN:NaN"
      ? "00:00"
      : song.durationFormatted()
  }

  dotStyles = () => {
    const { showDot, theme } = this.props
    if (showDot) {
      return {
        borderColor: theme.colors.text.primary,
        backgroundColor: theme.colors.text.primary,
      }
    }
    return { display: "none" }
  }

  render() {
    const { audioPlayer, onClick, showDuration, theme } = this.props
    const trackHeight = showDuration ? "16px" : "4px"
    const trackPadding = showDuration ? "4px 0" : "0"

    return (
      <AudioBarWrapper onClick={onClick}>
        {showDuration && (
          <Box display="flex" mr={3}>
            <Time>{secondsToMMSS(audioPlayer.pos)}</Time>
          </Box>
        )}
        <Slider
          handleStyle={this.dotStyles()}
          onChange={this.handleSliderChange}
          railStyle={{
            backgroundColor: `${theme.colors.background.inverted}33`,
          }}
          trackStyle={{
            backgroundColor: theme.colors.text.primary,
          }}
          min={0}
          max={SLIDER_MAX}
          value={this.value()}
          style={{
            height: trackHeight,
            padding: trackPadding,
          }}
        />
        {showDuration && (
          <Box display="flex" ml={3}>
            <Time>{this.duration()}</Time>
          </Box>
        )}
      </AudioBarWrapper>
    )
  }
}

MarketplaceAudioProgressBar.propTypes = {
  audioPlayer: PropTypes.instanceOf(AudioPlayerRecord),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  seek: PropTypes.func,
  showDot: PropTypes.bool,
  showDuration: PropTypes.bool,
  song: PropTypes.instanceOf(MarketplaceSongRecord),
  theme: PropTypes.object,
}

MarketplaceAudioProgressBar.defaultProps = {
  song: new MarketplaceSongRecord(),
  audioPlayer: new AudioPlayerRecord(),
  onClick: () => {},
  showDot: true,
  showDuration: true,
}

const mapStateToProps = (state) => ({
  audioPlayer: selectAudioPlayer()(state),
  song: selectAudioPlayerSong()(state),
})

const mapDispatchToProps = (dispatch) => ({
  openSignupModal: (actionType) =>
    dispatch(modalActions.open("SignUpModal", { action: actionType })),
  seek: (value) => dispatch(audioPlayerActions.seek(value)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withMixpanelTracking,
  withTheme
)(MarketplaceAudioProgressBar)
