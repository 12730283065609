import Immutable from "immutable"
import Pic from "./Pic"

const MarketplaceArtistRecord = Immutable.Record(
  {
    id: null,
    type: "marketplace_artists",

    // Attributes
    name: "",
    slugified_name: "",
    bio: "",
    pic: Pic,
    apple_id: "",
    spotify_id: "",
    song_titles: null,
    content_type: null,
  },
  "MarketplaceArtist"
)

class MarketplaceArtist extends MarketplaceArtistRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        pic: new Pic(args.pic),
      })
    )
  }

  popular() {
    return this.content_type === "Marketplace::PopularSong"
  }
}

export default MarketplaceArtist
