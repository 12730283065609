import { createAction } from "./utils"

export const types = {
  ADD_TO_PROJECT: "soundstripe/projectable/ADD_TO_PROJECT",
  REMOVE_FROM_PROJECT: "soundstripe/projectable/REMOVE_FROM_PROJECT",
}

export const actions = {
  addToProject: createAction(
    types.ADD_TO_PROJECT,
    "project",
    "projectable",
    "onSuccess"
  ),
  removeFromProject: createAction(
    types.REMOVE_FROM_PROJECT,
    "project",
    "projectable"
  ),
}
