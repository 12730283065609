import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { DragDropContext } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import key from "keymaster"
import { withScreenSize } from "hocs/withScreenSize"
import { AmobeeBeacon, AmobeeSuccess } from "components/Amobee"
import AnalyticsListener from "components/AnalyticsListener"
import AudioPlayer from "components/AudioPlayer"
import SoundEffectsAudioPlayer from "components/SoundEffectsAudioPlayer"
import PageContent from "components/PageContent"
import ModalRoot from "components/ModalRoot"
import SEO from "components/SEO"
import RecurringUpgradeModalLauncher from "components/RecurringUpgradeModalLauncher"
import MarketplaceWelcomeModalLauncher from "components/MarketplaceWelcomeModalLauncher"
import {
  MARKETING_FUNNEL_COOKIE,
  STATSIG_STABLE_ID_COOKIE,
  actions as cookieActions,
} from "middlewares/cookies"
import Cookies from "js-cookie"
import {
  loggedIn,
  authTokenExpired,
  currentUserIdFromToken,
  storeToken,
} from "utils/authentication"
import { isInternationalTestPlan } from "utils/plan"
import {
  initUserPilot,
  initAskNicely,
  initProfitwellRetain,
} from "utils/thirdPartyIntegrations"
import {
  onTrackingLoaded,
  updateMixpanelSuperProperties,
  updateMixpanelPeopleProperties,
  updateMixpanelPeopleAttribute,
  incrementMixpanelPeopleAttribute,
  identifyMixpanelUser,
} from "utils/tracking"
import { isProduction } from "utils/environment"
import {
  actions as currentUserActions,
  selectCurrentUserRecord,
} from "ducks/currentUser"
import { actions as uiActions } from "ducks/ui"
import { User as UserRecord } from "records"
import Box from "components/Box"
import Button from "components/Button/New"
import { Text } from "components/Typography"
import Input from "components/Input/New"
import Header from "components/Header"
import FrameioListener from "components/FrameioListener"
import ChargbeeInitializer from "components/Chargebee/Initializer"
import QueryStringModalLauncher from "components/QueryStringModalLauncher"
import UpsellModalLauncher from "components/ModalRoot/modals/UpsellModalLauncher"
import OfferBanner from "components/OfferBanner"
import AppBanner from "./AppBanner"
import AppVersionChecker from "./AppVersionChecker"
import TestAccountNotice from "./TestAccountNotice"
import DebugMenu from "./DebugMenu"
import DefaultTheme from "./DefaultTheme"
import isBefore from "date-fns/is_before"
import { formatDate } from "utils/dateUtils"
import StickyBanner from "components/StickyBanner"
import { checkPlayHistoryExpiration } from "utils/playHistory"
import { setLiveChatUserInfo } from "ducks/utils/currentUser"
import SignUpModalLauncher from "components/SignUpModalLauncher"
import UpgradeModalEstimateLoader from "components/UpgradeModalEstimateLoader"
import FlagLoader from "components/FlagLoader"
import {
  LogLevel,
  StatsigProvider,
  useClientAsyncInit,
} from "@statsig/react-bindings"
import useCurrentUser from "hooks/useCurrentUser"
import { sharedStableId } from "utils/statsig/stable-id"

const StatsigWrapper = ({ children }) => {
  const { currentUser } = useCurrentUser()

  const cookieStableId = Cookies.get(STATSIG_STABLE_ID_COOKIE)
  let statsigUser = {
    userID: currentUserIdFromToken(),
  }
  if (sharedStableId()) {
    // use the Stable Id from the cookie to initialize the client
    statsigUser = {
      ...statsigUser,
      customIDs: {
        stableID: sharedStableId(),
      },
    }
  }

  const { client, isLoading } = useClientAsyncInit(
    process.env.STATSIG_CLIENT_SDK_KEY,
    statsigUser,
    {
      environment: { tier: process.env.BUILD_ENV },
      fetchMode: isProduction() ? "cache-or-network" : "network-only",
      logLevel: isProduction() ? LogLevel.Error : LogLevel.Debug,
      // use proxy to avoid blockers
      networkConfig: {
        logEventUrl: `${process.env.STATSIG_PROXY_API_URL}/log_event`,
        initializeUrl: `${process.env.STATSIG_PROXY_API_URL}/initialize`,
      },
    }
  )

  useEffect(() => {
    if (currentUser?.id) {
      client?.updateUserAsync({ userID: currentUser.id })
      if (window.crispLoaded) {
        $crisp.push(["set", "session:data", ["user_id", currentUser.id || ""]])
      }
    }
  }, [currentUser?.id])

  const localStableId = client?.getContext().stableID

  const cookieDomain =
    process.env.BUILD_ENV !== "development"
      ? ".soundstripe.com"
      : process.env.COOKIE_DOMAIN
  const CookieAttributes = {
    expires: 365,
    path: "/",
    sameSite: "strict",
    domain: cookieDomain,
  }

  useEffect(() => {
    if (!isLoading && !cookieStableId) {
      // set the cookie with the local stable id
      Cookies.set(STATSIG_STABLE_ID_COOKIE, localStableId, CookieAttributes)
    }
    // get the cookie if it was just set and ensure Statsig is loaded w/ a cookie
    if (!isLoading && Cookies.get(STATSIG_STABLE_ID_COOKIE)) {
      window.statsigLoaded = true
      if (window.crispLoaded) {
        $crisp.push([
          "set",
          "session:data",
          ["statsig_stable_id", sharedStableId()],
        ])
      }
    }
  }, [localStableId, isLoading])

  if (isLoading) return null

  return <StatsigProvider client={client}>{children}</StatsigProvider>
}

const storeJS = require("utils/store.min.js")

const AppWrapper = ({ children }) => {
  const unlockExpiry = storeJS.get("unlockExpiry")
  const [unlocked, setUnlocked] = useState(
    isBefore(new Date(), new Date(unlockExpiry))
  )
  const [password, setPassword] = useState("")

  const checkPassword = () => {
    setUnlocked(password === process.env.APP_GATEWAY_PASSWORD)
    const now = new Date()
    storeJS.set(
      "unlockExpiry",
      formatDate(
        new Date(now.getTime() + 1000 * 60 * 60 * 24 * 7),
        "YYYY-MM-DDTHH:mm:ssZ"
      )
    )
  }

  return !process.env.APP_GATEWAY_PASSWORD || unlocked ? (
    <Box>{children}</Box>
  ) : (
    <Box maxWidth="20rem" mx="auto" mt={6}>
      <Text fontWeight="semiBold">Enter password</Text>
      <Input type="password" onChange={(e) => setPassword(e.target.value)} />
      <Button my={4} onClick={checkPassword}>
        Unlock
      </Button>
    </Box>
  )
}

class App extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    currentUser: PropTypes.instanceOf(UserRecord),
    loadCurrentUser: PropTypes.func.isRequired,
    setUTMCookies: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    toggleThemeMode: PropTypes.func.isRequired,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      sendUserInfo: false,
    }
    this.refreshTimer = null
  }

  componentDidMount = () => {
    const {
      setUTMCookies,
      toggleThemeMode,
      signOut,
      loadCurrentUser,
      currentUser,
      getCookie,
      removeCookie,
    } = this.props

    this.startTime = new Date()
    checkPlayHistoryExpiration()
    setUTMCookies()
    if (process.env.BUILD_ENV !== "production") {
      key("/", (e) => {
        e.preventDefault()
        toggleThemeMode()
      })
    }

    if (loggedIn()) {
      if (authTokenExpired()) {
        signOut()
      } else if (!currentUser.id) {
        loadCurrentUser()
      } else if (currentUser.id) {
        setLiveChatUserInfo(currentUser)
        initUserPilot(currentUser)
        initAskNicely(currentUser)
        initProfitwellRetain(currentUser)
      }
    }

    if (getCookie(MARKETING_FUNNEL_COOKIE)) {
      if (loggedIn()) signOut()

      const token = getCookie(MARKETING_FUNNEL_COOKIE)
      removeCookie(MARKETING_FUNNEL_COOKIE)
      storeToken(token)
      if (!currentUser.id) {
        loadCurrentUser()
      }
    }

    const loadingCheck = setInterval(() => {
      // We don't want to flash the loading screen, make sure it's
      // at least been a half-second.
      if (window.loadingTime >= 500) {
        window.loadingScreen.finish()
        clearInterval(loadingCheck)
        clearInterval(window.loadingTimer)
      }
    }, 100)

    const apiHost = isProduction()
      ? "https://metrics.soundstripe.com"
      : "https://staging-metrics.soundstripe.com"

    mixpanel.init(process.env.MIXPANEL_TOKEN, {
      api_host: apiHost,
      loaded: () => {
        window.trackingLoaded = true
      },
    })

    if (loggedIn()) {
      if (!authTokenExpired()) {
        this.setState({ sendUserInfo: true })
      }
    } else {
      onTrackingLoaded(() => {
        const distinctId = mixpanel.get_distinct_id()
        identifyMixpanelUser(distinctId)
        updateMixpanelPeopleAttribute("Last Visited", new Date().toISOString())
        incrementMixpanelPeopleAttribute("Visits Count")
      })
    }

    onTrackingLoaded(() => {
      mixpanel.register_once({ Referrer: document.referrer })
      mixpanel.people.set_once({ "First Visit": new Date().toISOString() })
    })

    window.addEventListener("mousewheel", (e) => {
      if (e.deltaY === 1) {
        e.preventDefault()
      }
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    // If sendUserInfo was just set to true and currentUser exists, update.
    // If sendUserInfo is true and currentUser DIDN'T exist but does now, update.
    if (
      (!prevState.sendUserInfo &&
        this.state.sendUserInfo &&
        this.props.currentUser.id) ||
      (this.state.sendUserInfo &&
        !prevProps.currentUser.id &&
        this.props.currentUser.id)
    ) {
      updateMixpanelSuperProperties(this.props.currentUser)
      updateMixpanelPeopleProperties(this.props.currentUser)
      updateMixpanelPeopleAttribute("Last Signed In", new Date().toISOString())
      incrementMixpanelPeopleAttribute("Sign Ins Count")
    }

    if (this.props.currentUser?.subscription && !this.refreshTimer) {
      this.handleSubscriptionEndedCheck()
    }

    // If currentUser DIDN'T exist but does now, update profitwell because it wouldn't have been set in mount.
    if (!prevProps.currentUser?.id && this.props.currentUser?.id) {
      setLiveChatUserInfo(this.props.currentUser)
      initUserPilot(this.props.currentUser)
      initAskNicely(this.props.currentUser)
      initProfitwellRetain(this.props.currentUser)
    }
  }

  whiteBg = () => !!this.logoOnly()

  signUpMode = () =>
    this.locationMatches(/^\/signup$/) ||
    this.locationMatches(/^\/checkout$/) ||
    this.locationMatches(/user_info/)

  hideHeader = () =>
    this.locationMatches(/^\/enterprise\/signup$/) ||
    this.locationMatches(/onboard/)

  singleSongCheckoutMode = () =>
    this.locationMatches(/^\/license_checkout$/) ||
    this.locationMatches(/\/sale\//)

  userInfoPageActive = () => this.locationMatches(/user_info/)

  logoOnly = () => {
    return (
      this.locationMatches(/^\/sign_in$/) ||
      this.locationMatches(/^\/signup$/) ||
      this.locationMatches(/^\/checkout$/) ||
      this.locationMatches(/^\/user_info$/) ||
      this.locationMatches(/^\/forgot_password$/)
    )
  }

  locationMatches = (regEx) => {
    const path = this.context.router.getCurrentLocation().pathname
    return path && path.match(regEx) != null
  }

  handleSubscriptionEndedCheck = () => {
    const { currentUser, signOut } = this.props
    const subscriptionEnd = currentUser?.subscription?.current_term_end
    if (!subscriptionEnd) return null

    const now = new Date()
    const end = new Date(subscriptionEnd)
    const diff = end - now
    const refreshTime = 1000 * 60 * 60 * 24 // 24 hours

    if (diff > refreshTime) {
      this.refreshTimer = setTimeout(
        this.handleSubscriptionEndedCheck,
        refreshTime
      )
    } else {
      this.refreshTimer = setTimeout(signOut, diff)
    }
  }

  render() {
    const { currentUser, isMobile } = this.props

    return (
      <StatsigWrapper>
        <DefaultTheme>
          <FrameioListener>
            <AppWrapper>
              <SEO title="" description="Unlimited music for filmmakers" />
              <AnalyticsListener />
              <TestAccountNotice />
              <AppBanner />
              <AppVersionChecker />
              <OfferBanner />
              <StickyBanner />
              {!this.hideHeader() && (
                <Header
                  currentUser={currentUser}
                  userInfoPageActive={this.userInfoPageActive()}
                  logoOnly={this.logoOnly()}
                  signUpMode={this.signUpMode()}
                  singleSongCheckoutMode={this.singleSongCheckoutMode()}
                  isMobile={isMobile && !this.hideHeader()}
                />
              )}
              <PageContent
                id="pageContent"
                className={classNames({
                  sidebarVisible: this.locationMatches(/^\/music$/),
                  whiteBg: this.whiteBg(),
                  singleSongCheckout: this.singleSongCheckoutMode(),
                })}
              >
                {this.props.children}
              </PageContent>
              <AudioPlayer currentUser={currentUser} />
              <SoundEffectsAudioPlayer />
              <ModalRoot />
              <AmobeeBeacon />
              <AmobeeSuccess />
              <ChargbeeInitializer />
              <QueryStringModalLauncher />
              <MarketplaceWelcomeModalLauncher />
              <UpsellModalLauncher />
              <SignUpModalLauncher />
              <FlagLoader />
              <UpgradeModalEstimateLoader />
              {process.env.BUILD_ENV !== "production" && <DebugMenu />}
              {loggedIn() &&
                currentUser.planId() &&
                !isInternationalTestPlan(currentUser.planId()) && (
                  <>
                    <RecurringUpgradeModalLauncher />
                    <UpsellModalLauncher />
                  </>
                )}
            </AppWrapper>
          </FrameioListener>
        </DefaultTheme>
      </StatsigWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUserRecord()(state),
  client: state.client,
})

const mapDispatchToProps = (dispatch) => ({
  getCookie: (key) => dispatch(cookieActions.getCookie(key)),
  removeCookie: (key) => dispatch(cookieActions.removeCookie(key, true)),
  toggleThemeMode: () => dispatch(uiActions.toggleThemeMode()),
  loadCurrentUser: () => dispatch(currentUserActions.load()),
  signOut: () => dispatch(currentUserActions.signOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(withScreenSize(App)))
