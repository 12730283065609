import PropTypes from "prop-types"
import React, { memo } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { Song as SongRecord } from "records"
import Box from "components/Box"
import { actions as modalActions } from "ducks/modal"
import Icon from "components/Icon"
import {
  ICON_ADD_TO_FOLDER,
  ICON_SS_LIST_VIEW,
} from "components/Icon/constants"
import useCurrentUser from "hooks/useCurrentUser"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background-color: ${({ theme }) => theme.colors.background.layered};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  width: 80%;
  position: absolute;
  bottom: 100%;
  right: 2rem;
  z-index: 500;
  border-bottom: 2px solid ${({ theme }) => theme.audioPlayer.borderColor};
  border-top-left-radius: 5px;
`

const Option = styled.div`
  cursor: pointer;
  border: 2px transparent;
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.primary};
  vertical-align: middle;
  line-height: 50px;
  padding-left: 1rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: ${({ theme }) => theme.colors.background.elevationLow};
  }
`

const CollectionPopover = ({ song }) => {
  const dispatch = useDispatch()
  const { currentUser } = useCurrentUser()

  const openModal = (modalKey, data) => {
    dispatch(modalActions.open(modalKey, data))
  }

  const onAddToProject = () => {
    if (!currentUser.loggedIn()) {
      openModal("SignUpModal", {
        action: "project",
        afterSignup: () => onAddToProject(),
      })
    } else {
      openModal("AddMediaToProjectModal", {
        records: [song],
        mediaType: "songs",
      })
    }
  }

  const onAddToPlaylist = () => {
    if (!currentUser.loggedIn()) {
      openModal("SignUpModal", {
        action: "playlist",
        afterSignup: () =>
          openModal("AddMediaToPlaylistModal", { record: song }),
      })
    } else {
      openModal("AddMediaToPlaylistModal", { record: song })
    }
  }

  return (
    <Wrapper>
      <Option onClick={onAddToProject}>
        <Box mr={2} as="span">
          <Icon
            icon={ICON_ADD_TO_FOLDER}
            size={18}
            strokeWidth="1.5"
            color="text.white"
          />
        </Box>
        Add to Project
      </Option>
      <Option onClick={onAddToPlaylist}>
        <Box mr={2} as="span">
          <Icon mr={4} icon={ICON_SS_LIST_VIEW} size={18} />
        </Box>
        Add to Playlist
      </Option>
    </Wrapper>
  )
}

CollectionPopover.propTypes = {
  song: PropTypes.instanceOf(SongRecord).isRequired,
}

export default memo(CollectionPopover)
