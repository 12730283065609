import useFlag from "hooks/useFlag"
import { useDebugFlag } from "context/DebugFlagContext"

export default () => {
  const { debugFlags } = useDebugFlag()
  const { enabled: flagFullyEnabled } = useFlag("new_market_search_ui")
  const debugFlagEnabled = debugFlags["NEW_SEARCH_UI"]

  return {
    enabled: debugFlagEnabled || flagFullyEnabled,
  }
}
