import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect, useDispatch } from "react-redux"
import classNames from "classnames"
import escapeRegExp from "lodash/escapeRegExp"
import useMixpanelTracking from "hooks/useMixpanelTracking"
import useOffer from "hooks/useOffer"
import { User as UserRecord } from "records"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { selectPlayerVisible } from "ducks/audioPlayer"
import withAccountLinks from "hocs/withAccountLinks"
import { makeSelectLocationState } from "containers/App/selectors"
import Box from "components/Box"
import Button from "components/Button/New"
import Icon from "components/Icon"
import {
  ICON_SS_CHEVRON_DOWN,
  ICON_SS_CHEVRON_UP,
} from "components/Icon/constants"
import ThemeModeToggle from "components/ThemeModeToggle"
import { loggedIn } from "utils/authentication"
import { actions as modalActions } from "ducks/modal"
import {
  actions as uiActions,
  selectSidebarSubmenuOpen,
  selectProfileSubmenuOpen,
  selectMyMediaSubmenuOpen,
  selectAppBannerVisible,
} from "ducks/ui"
import MobileMenuCloseButton from "./MobileMenuCloseButton"
import SidebarLink from "./SidebarLink"
import {
  LinkGroupHeader,
  SidebarInner,
  SidebarWrapper,
  sidebarLinkStyles,
} from "./sidebarLinkStyles"
import { TextGradient } from "components/Typography"
import styled from "styled-components"
import { playlistRequestCrispChatOpen } from "utils/crisp"
import useMarketplaceMixpanelTracking from "hooks/useMarketplaceMixpanelTracking"
import UserNotificationsCount from "../UserNotificationsCount"
import useAppNotifications from "hooks/useAppNotifications"

const ThemeWrapper = styled.div`
  ${sidebarLinkStyles};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.text.secondary};
  width: 100%;
`

const ThemeLabel = styled.label`
  width: 100%;
`

const SidebarMenu = (props, context) => {
  const {
    currentUser,
    extensionsLinkObject,
    onMobileSidebarClose,
    referralLinkObject,
    signOutLinkObject,
    toggleLiveChat,
    subscriptionLinkObject,
    billingLinkObject,
    contentIdLinkObject,
    profileLinkObject,
    teamMembersLinkObject,
    apiAccessLinkObject,
    toggleThemeMode,
  } = props
  const [showMyMediaSubmenu, setShowMyMediaSubmenu] = useState(false)
  const [showAccountSubmenu, setShowAccountSubmenu] = useState(false)
  const { trackMixpanel } = useMixpanelTracking()
  const { trackMarketplaceMixpanel } = useMarketplaceMixpanelTracking()
  const offer = useOffer()
  const dispatch = useDispatch()
  const { enabled: appNotificationsEnabled } = useAppNotifications()
  const notifLinkText = loggedIn() ? "Notifications" : "What's New"

  const handleOpenLiveChat = () => {
    onMobileSidebarClose()

    if ($crisp) {
      if ($crisp.is("chat:opened")) {
        $crisp.push(["do", "chat:close"])
      } else {
        $crisp.push(["do", "chat:open"])
      }
    }

    toggleLiveChat()
  }

  const upgradeModalOpen = () => {
    dispatch(modalActions.open("UpgradeModal", { context: "Header Button" }))
  }

  const openUserNotificationsSlideout = () => {
    onMobileSidebarClose()
    trackMixpanel("Clicked Notifications Link", {
      Context: "Sidebar Menu",
      "Link Text": notifLinkText,
    })
    dispatch(modalActions.open("UserNotificationsSlideout"))
  }

  const handleCloseSidebarMenu = () => {
    onMobileSidebarClose()
  }

  const handleWelcomeTour = () => {
    dispatch(modalActions.open("FeatureTourModal"))
  }

  const locationMatches = (regEx) =>
    context.router.getCurrentLocation().pathname.match(regEx) != null

  const renderLink = (link) => {
    if (link) {
      const props = {
        className: classNames(link.className, {
          active:
            link.to && locationMatches(new RegExp(escapeRegExp(link.to), "gi")),
        }),
        key: link.to || `/${link.content}`,
        onClick: link.onClick || handleCloseSidebarMenu,
        to: link.to,
        "data-cy": link.dataCy,
        ...link.props,
      }

      if (link.textGradient) {
        return (
          <SidebarLink {...props}>
            <TextGradient>{link.content}</TextGradient>
          </SidebarLink>
        )
      }

      return <SidebarLink {...props}>{link.content}</SidebarLink>
    }
  }

  const renderMyMediaSubmenu = () => {
    if (!loggedIn()) return null
    const onTeam = currentUser.onTeam()

    return (
      <>
        {onTeam && <LinkGroupHeader>Team</LinkGroupHeader>}

        {
          <Box pl={onTeam ? 4 : 0}>
            {renderLink({
              content: "Playlists",
              to: "/my_media/playlists/music",
            })}
          </Box>
        }

        {!currentUser.enterpriseApi() && (
          <Box pl={onTeam ? 4 : 0}>
            {renderLink({
              content: "Projects",
              to: "/my_media/projects",
            })}
          </Box>
        )}

        {
          <Box pl={onTeam ? 4 : 0}>
            {renderLink({
              content: "Downloads",
              to: "/licenses/music",
            })}
          </Box>
        }

        {onTeam && <LinkGroupHeader>My</LinkGroupHeader>}

        {
          <Box pl={onTeam ? 4 : 0}>
            {renderLink({
              content: "Favorites",
              to: "/my_media/favorites/music",
            })}
          </Box>
        }

        {
          <Box pl={onTeam ? 4 : 0}>
            {renderLink({
              content: "Following",
              to: "/my_media/following/playlists",
            })}
          </Box>
        }

        {currentUser.canRequestPlaylist() &&
          renderLink({
            content: "Request Playlist",
            onClick: () => {
              onMobileSidebarClose()
              playlistRequestCrispChatOpen(currentUser.email)
            },
          })}
      </>
    )
  }

  const renderProfileSubmenu = () => {
    if (!loggedIn()) return null

    const menuItems = [
      profileLinkObject,
      subscriptionLinkObject,
      billingLinkObject,
      contentIdLinkObject,
      teamMembersLinkObject,
      apiAccessLinkObject,
    ]

    return <>{menuItems.map((link) => renderLink(link))}</>
  }

  const handleToggleMyMediaSubmenu = () => {
    setShowMyMediaSubmenu(!showMyMediaSubmenu)
    showAccountSubmenu && setShowAccountSubmenu(!showAccountSubmenu)
  }

  const handleToggleAccountSubmenu = () => {
    setShowAccountSubmenu(!showAccountSubmenu)
    showMyMediaSubmenu && setShowMyMediaSubmenu(!showMyMediaSubmenu)
  }

  const mobileAppLink = renderLink({
    className: "subtle",
    to: "/mobile-app",
    onClick: () => {
      trackMixpanel("Clicked Mobile App Link", {
        Context: "Sidebar Menu",
        "Link Text": "Mobile App",
      })
      handleCloseSidebarMenu()
    },
    content: "Mobile App",
  })

  return (
    <SidebarWrapper role="dialog" aria-labelledby="dialog1Title">
      <h2 className="sr-only">Menu</h2>
      <Box
        position="sticky"
        top="0"
        left="0"
        display="flex"
        justifyContent="flex-end"
      >
        <MobileMenuCloseButton onClick={handleCloseSidebarMenu} />
      </Box>
      <SidebarInner>
        {renderLink({
          className: "primary",
          content: "Home",
          to: "/",
          dataCy: "mobile-nav-home-link",
        })}
        {renderLink({
          className: "primary",
          content: "Music",
          to: "/royalty-free-music",
          dataCy: "mobile-nav-music-link",
        })}
        {renderLink({
          className: "primary",
          content: "Video",
          to: "/video",
          dataCy: "mobile-nav-video-link",
        })}
        {renderLink({
          className: "primary",
          content: "Sound Effects",
          to: "/sound-effects",
          dataCy: "mobile-nav-sfx-link",
        })}
        {renderLink({
          className: "primary",
          content: "Playlists",
          to: "/playlists",
          dataCy: "mobile-nav-playlists-link",
        })}
        {renderLink({
          className: "primary",
          content: (
            <Box display="flex" alignItems="center">
              <Box as="span" fontSize="xl" fontWeight="medium">
                Market
              </Box>
              <Box
                color="pink400"
                fontSize="xs"
                borderRadius="default"
                p={1}
                backgroundColor="pink000"
                ml={2}
                lineHeight="locked"
              >
                Beta
              </Box>
            </Box>
          ),
          to: "/market",
          onClick: () => {
            trackMarketplaceMixpanel("Clicked Element", {
              Context: "Marketplace Link - Sidebar Menu",
            })
            handleCloseSidebarMenu()
          },
          dataCy: "mobile-nav-store-link",
        })}
      </SidebarInner>
      {loggedIn() && (
        <SidebarInner>
          <Box display="flex" flexDirection="column">
            <LinkGroupHeader onClick={handleToggleMyMediaSubmenu}>
              My Media
              <Icon
                icon={
                  showMyMediaSubmenu ? ICON_SS_CHEVRON_UP : ICON_SS_CHEVRON_DOWN
                }
                size={20}
              />
            </LinkGroupHeader>
            {showMyMediaSubmenu && (
              <Box display="flex" flexDirection="column" mb={1}>
                {renderMyMediaSubmenu()}
              </Box>
            )}
          </Box>
          <Box>
            <LinkGroupHeader onClick={handleToggleAccountSubmenu}>
              Account
              <Icon
                icon={
                  showAccountSubmenu ? ICON_SS_CHEVRON_UP : ICON_SS_CHEVRON_DOWN
                }
                size={20}
              />
            </LinkGroupHeader>
            {showAccountSubmenu && (
              <Box display="flex" flexDirection="column">
                {renderProfileSubmenu()}
              </Box>
            )}
          </Box>
        </SidebarInner>
      )}
      <SidebarInner>
        {loggedIn() && currentUser.isUpgradeEligible() && (
          <>
            {renderLink({
              className: offer ? "offer" : "subtle",
              content: offer ? offer.upgradeCallout.sidebar : "Upgrade",
              onClick: () => {
                upgradeModalOpen()
                onMobileSidebarClose()
              },
            })}
          </>
        )}
        {loggedIn() && !currentUser.subscription && (
          <>
            {renderLink({
              className: offer ? "offer" : "subtle",
              content: offer ? offer.upgradeCallout.sidebar : "Subscribe",
              to: "/pricing",
              textGradient: !offer,
              dataCy: "mobile-nav-subscribe-link",
            })}
          </>
        )}
        {loggedIn() &&
          currentUser.free_account &&
          renderLink({
            className: "subtle",
            content: "Pricing",
            to: "/pricing",
            dataCy: "mobile-nav-pricing-link",
          })}
        {!loggedIn() && mobileAppLink}
        {renderLink({
          className: "subtle",
          content: "Live Chat",
          onClick: handleOpenLiveChat,
        })}
        {loggedIn() && (
          <>
            {appNotificationsEnabled &&
              renderLink({
                className: "subtle",
                content: (
                  <Box display="flex" alignItems="center">
                    {notifLinkText}
                    <UserNotificationsCount />
                  </Box>
                ),
                onClick: openUserNotificationsSlideout,
              })}
            {mobileAppLink}
            {renderLink({
              ...extensionsLinkObject,
              className: classNames(extensionsLinkObject.className, "subtle"),
            })}
            {currentUser.canAccessReferralProgram() &&
              renderLink({
                ...referralLinkObject,
                className: classNames(referralLinkObject.className, "subtle"),
              })}
            {renderLink({
              className: "subtle",
              content: "help@soundstripe.com",
              onClick: (e) => {
                window.location = "mailto:help@soundstripe.com"
                handleCloseSidebarMenu()
                e.preventDefault()
              },
            })}
            {renderLink({
              className: "subtle",
              content: "Start Tour",
              onClick: () => {
                handleWelcomeTour()
                onMobileSidebarClose()
              },
            })}
            <ThemeWrapper
              onClick={(e) => {
                e.stopPropagation()
                toggleThemeMode()
              }}
            >
              <ThemeLabel>Dark Mode</ThemeLabel>
              <ThemeModeToggle />
            </ThemeWrapper>
            <Box mt={6} />
            {renderLink({
              ...signOutLinkObject,
              className: classNames(signOutLinkObject.className, "subtle"),
            })}
          </>
        )}
        {!loggedIn() && (
          <>
            {renderLink({
              className: "subtle",
              content: "About Soundstripe",
              to: "https://www.soundstripe.com/",
            })}
            {renderLink({
              className: "subtle",
              content: "Pricing",
              to: "/pricing",
              onClick: () => {
                handleCloseSidebarMenu()
              },
              dataCy: "mobile-nav-pricing-link",
            })}
            <Box display="flex" mt={6}>
              <Button
                mr={3}
                to="/sign_in"
                onClick={handleCloseSidebarMenu}
                data-cy="mobile-nav-signin-btn"
              >
                Sign In
              </Button>
              <Button
                appearance="cta"
                to="/signup"
                onClick={handleCloseSidebarMenu}
                data-cy="mobile-nav-signup-btn"
              >
                Sign Up
              </Button>
            </Box>
          </>
        )}
      </SidebarInner>
    </SidebarWrapper>
  )
}

SidebarMenu.contextTypes = {
  router: PropTypes.object.isRequired,
}

SidebarMenu.propTypes = {
  apiAccessLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  appBannerVisible: PropTypes.bool,
  billingLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  className: PropTypes.string,
  contentIdLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  currentUser: PropTypes.instanceOf(UserRecord),
  extensionsLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  subscriptionLinkObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  onMobileSidebarClose: PropTypes.func.isRequired,
  playerVisible: PropTypes.bool.isRequired,
  profileLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  referralLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  sidebarSubmenuOpen: PropTypes.bool.isRequired,
  signOutLinkObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  teamMembersLinkObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  toggleLiveChat: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  toggleProfileSubmenu: () => dispatch(uiActions.toggleProfileSubmenu()),
  toggleSidebarSubmenu: () => dispatch(uiActions.toggleSidebarSubmenu()),
  toggleMyMediaSubmenu: () => dispatch(uiActions.toggleMyMediaSubmenu()),
  toggleLiveChat: () => dispatch(uiActions.toggleLiveChat()),
  toggleThemeMode: () => dispatch(uiActions.toggleThemeMode()),
})

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUserRecord()(state),
  playerVisible: selectPlayerVisible()(state),
  location: makeSelectLocationState()(state),
  sidebarSubmenuOpen: selectSidebarSubmenuOpen()(state),
  myMediaSubmenuOpen: selectMyMediaSubmenuOpen()(state),
  profileSubmenuOpen: selectProfileSubmenuOpen()(state),
  appBannerVisible: selectAppBannerVisible()(state),
})

export default withAccountLinks(
  connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
)
