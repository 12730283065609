import { List, Record } from "immutable"
import { loggedIn, MONTHLY_PLAN_IDS } from "utils/authentication"
import Avatar from "../Avatar"
import Subscription from "./Subscription"
import Permissions from "./Permissions"
import { vatPrefix } from "utils/vat"
import { isInternationalTestPlan } from "utils/plan"

import {
  ENTERPRISE_API_PLAN_IDS,
  PACKAGED_PLAN_IDS,
  PRO_PRO_PLUS_IDS,
  PLAN_IDS,
  PRO_PLAN_IDS,
  PRO_PLUS_PLAN_IDS,
  CREATOR_PLAN_IDS,
  TWITCH_PLAN_IDS,
  ENTERPRISE_BUSINESS_PLAN_IDS,
} from "utils/authentication"

const UserRecord = Record(
  {
    id: null,
    type: "users",

    // Attributes
    account_id: null,
    account_owner_id: null,
    agreed_to_terms: true,
    agreed_to_updated_terms: true,
    apple_authenticated: false,
    apply_referral_coupon: false,
    asknicely_email_hash: null,
    audience_reach: "",
    avatar: Avatar,
    billing_address: {},
    business_type: "",
    can_use_ai_song_editing: false,
    chargebee_id: null,
    churned_account: false,
    company_name: "",
    content_id_notice_last_viewed_at: null,
    content_id_notice_opted_out: false,
    content_type: "",
    cookie_yes_consent: "",
    created_at: null,
    delinquent: false,
    distribution_type: [],
    email: "",
    enforce_team_member_limit: false,
    entity_type: "",
    entity_other: "",
    entity_size: "",
    favorited_sound_effect_ids: List(),
    favorited_song_ids: List(),
    favorited_video_ids: List(),
    favorited_marketplace_song_ids: List(),
    followed_playlist_ids: List(),
    followed_artist_ids: List(),
    first_name: "",
    frameio_account_id: "",
    free_account: false, // has never had a subscription
    free_song_license_history: [],
    free_user_onboarding_complete: false,
    geoCountry: "",
    google_authenticated: false,
    industries: [],
    last_name: "",
    license_change_dialog_viewed_at: null,
    license_history: {},
    lifetime_license_history: {},
    permissions: Permissions,
    pricing_funnel_key: null,
    primary_payment_source_id: null,
    project_types: [],
    projects_count: 0,
    playlists_count: 0,
    product_suggestions: [],
    promotional_credits: 0,
    prosperstack_signed_payload: null,
    quarterly_offer_eligible: true,
    recently_played_songs_count: 0,
    redeemed_holiday_coupon: false,
    referral_link: "",
    referring_user_id: null,
    requires_company: false,
    role: "",
    sales_count: 0,
    single_song_sales_count: 0,
    song_row_cart: false,
    subscription: null,
    sync_agent_privileges: false,
    test_user: false,
    total_accumulated_referral_credits: 0,
    twitch_account: false,
    unread_notification_count: 0,
    upsell_eligible: false,
    usage_frequency: {},
    user_type: "",
    vat_number: null,
    youtube_channel_limit: 0,
  },
  "User"
)

class User extends UserRecord {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        type: "users",
        avatar: new Avatar(args.avatar),
        favorited_sound_effect_ids: List(args.favorited_sound_effect_ids),
        favorited_song_ids: List(args.favorited_song_ids),
        favorited_video_ids: List(args.favorited_video_ids),
        favorited_marketplace_song_ids: List(
          args.favorited_marketplace_song_ids
        ),
        followed_playlist_ids: List(args.followed_playlist_ids),
        followed_artist_ids: List(args.followed_artist_ids),
        permissions: new Permissions(args.permissions),
        subscription: args.subscription
          ? new Subscription(args.subscription)
          : null,
      })
    )
  }

  fullVatNumber() {
    const { country } = this.billing_address
    if (!country || !this.vat_number) return ""
    return vatPrefix(country) + this.vat_number
  }

  hasFreeSongsAccess() {
    return false
  }

  atFreeSongDownloadLimit() {
    return true
  }

  canDownloadFreeSong(song) {
    return (
      song.free &&
      (!this.atFreeSongDownloadLimit() ||
        this.licensedSongIds().includes(song.id))
    )
  }

  canAccessAdobeExtension() {
    return this.permissions.adobe_extension
  }

  canAccessBilling() {
    return this.permissions.access_billing
  }

  canAccessFrameioExtension() {
    return this.permissions.frameio_extension
  }

  canAccessReferralProgram() {
    return false
  }

  canBulkDownload() {
    return this.permissions.bulk_download
  }

  canBulkDownloadSFX() {
    return this.canLicenseSoundEffects() && this.canBulkDownload()
  }

  canBulkDownloadMusic() {
    return this.canLicenseSongs() && this.canBulkDownload()
  }

  canBulkDownloadVideos() {
    return this.canLicenseVideos() && this.canBulkDownload()
  }

  canDownloadStems() {
    return this.permissions.song_stems
  }

  canDownloadUnlimitedProjects() {
    return (
      PRO_PRO_PLUS_IDS.includes(this.planId()) ||
      this.enterprise() ||
      ENTERPRISE_BUSINESS_PLAN_IDS.includes(this.planId())
    )
  }

  canLicenseAny() {
    return (
      this.permissions.license_songs ||
      this.permissions.license_sound_effects ||
      this.permissions.license_videos
    )
  }

  canLicenseSoundEffects() {
    return this.permissions.license_sound_effects
  }

  canLicenseSongs() {
    return this.permissions.license_songs
  }

  canLicenseVideos() {
    return (
      this.permissions.license_videos &&
      this.hasSubscription() &&
      !this.subscriptionPaused() &&
      !this.delinquent &&
      this.subscription?.status !== "incomplete"
    )
  }

  canManagePlaylists() {
    return this.loggedIn()
  }

  canFavorite() {
    return this.loggedIn()
  }

  canManageTeam() {
    return this.permissions.manage_team
  }

  canManageSubscription() {
    return !this.onTeam() || this.canManageTeam()
  }

  canRequestCustomPlaylists() {
    return this.permissions.request_custom_playlists
  }

  hasFrameioAccount() {
    return !!this.frameio_account_id
  }

  hasPaymentSource() {
    return !!this.primary_payment_source_id
  }

  hasSubscription() {
    return !!(this.subscription && this.subscription.id)
  }

  freeOrChurned() {
    return this.free_account || this.churned_account
  }

  freeOrCreator() {
    return this.freeOrChurned() || this.creator()
  }

  initials() {
    const first = this.first_name.charAt(0)
    const last = this.last_name?.charAt(0) || ""
    return first + last
  }

  teamMember() {
    return this.role === "team_member" || false
  }

  teamMemberLimit() {
    if (this.subscription?.custom_team_seat_limit === 0) {
      return Infinity
    } else if (this.subscription?.custom_team_seat_limit) {
      return this.subscription.custom_team_seat_limit
    } else if (this.subscription?.isEnterpriseBusiness()) {
      return 3
    } else {
      return 5
    }
  }

  isUpgradeEligible() {
    return (
      !this.teamMember() &&
      this.subscription?.upgradeEligible() &&
      !this.enterpriseApi()
    )
  }

  isUpsellEligible() {
    const notTeamMember = !this.teamMember()
    const subscriptionUpsellEligible = this.subscription?.upsell_eligible
    return notTeamMember && subscriptionUpsellEligible
  }

  loggedIn() {
    return loggedIn()
  }

  name() {
    const name = `${this.first_name} ${this.last_name}`
    return name.trim()
  }

  subscriptionPaused() {
    return this.subscription?.isPaused()
  }

  planId() {
    return this.subscription && this.subscription.plan_id
  }

  onTeam() {
    return (
      this.subscription?.isTeam() ||
      this.subscription?.isEnterprise() ||
      this.subscription?.isEnterpriseBusiness()
    )
  }

  showTeamUpsell() {
    return (
      this.pro() || (this.proPlus() && !this.subscription.grandfathered_teams)
    )
  }

  enterprise() {
    return this.subscription?.isEnterprise()
  }

  isAnyEnterprise() {
    return (
      this.enterprise() || this.enterpriseBusiness() || this.enterpriseApi()
    )
  }

  hasMonthlyPlan() {
    return this.planId() && MONTHLY_PLAN_IDS.includes(this.planId())
  }

  twitch() {
    return this.planId()?.includes("twitch")
  }

  creator() {
    return CREATOR_PLAN_IDS.includes(this.planId())
  }

  creatorOrTwitch() {
    return [...CREATOR_PLAN_IDS, ...TWITCH_PLAN_IDS].includes(this.planId())
  }

  pro() {
    return PRO_PLAN_IDS.includes(this.planId())
  }

  proPlus() {
    return PRO_PLUS_PLAN_IDS.includes(this.planId())
  }

  enterpriseBusiness() {
    return ENTERPRISE_BUSINESS_PLAN_IDS.includes(this.planId())
  }

  favoritedSoundEffect(id) {
    return this.favorited_sound_effect_ids.includes(Number(id))
  }

  favoritedSong(id) {
    return this.favorited_song_ids.includes(Number(id))
  }

  favoritedVideo(id) {
    return this.favorited_video_ids.includes(Number(id))
  }

  favoritedMarketplaceSong(id) {
    return this.favorited_marketplace_song_ids.includes(Number(id))
  }

  isFollowing(resource) {
    if (resource.type === "artists") {
      return this.followsArtist(resource.id)
    }

    if (resource.type === "playlists") {
      return this.followsPlaylist(resource.id)
    }
  }

  followsArtist(id) {
    return this.followed_artist_ids.includes(Number(id))
  }

  followsPlaylist(id) {
    return this.followed_playlist_ids.includes(Number(id))
  }

  owner() {
    return this.role === "owner"
  }

  teamOwner() {
    return this.owner() && this.onTeam()
  }

  licensedSongIds() {
    return Object.keys(this.license_history.songs || {})
  }

  hasPreviouslyDownloadedSongs() {
    return this.licensedSongIds() > 0 || this.hasSingleSongPurchases()
  }

  hasSingleSongPurchases() {
    return this.lifetime_license_history?.songs?.length > 0
  }

  canAccessContentId() {
    return (
      (this.hasSubscription() && !this.twitch()) ||
      this.hasPreviouslyDownloadedSongs()
    )
  }

  hasPackagedPlanId() {
    return PACKAGED_PLAN_IDS.includes(this.planId())
  }

  enterpriseApi() {
    return ENTERPRISE_API_PLAN_IDS.includes(this.planId())
  }

  hasApiAccess() {
    return this.subscription?.has_api_access || this.free_account
  }

  hasApiAddons() {
    return this.subscription?.has_api_addons
  }

  hasApiTrialAccessOnly() {
    return this.hasApiAccess() && !this.hasApiAddons()
  }

  hasAiSearchAccess() {
    if (this.twitch()) return false
    return true
  }

  canUseContentIdClearance() {
    return (
      this.permissions.content_id_clearance || this.hasSingleSongPurchases()
    )
  }

  showRecentlyPlayedSongs() {
    return this.recently_played_songs_count >= 6
  }

  canRequestPlaylist() {
    const proPlusPlans = [
      PLAN_IDS.PRO_PLUS_MONTHLY,
      PLAN_IDS.PRO_PLUS_QUARTERLY,
      PLAN_IDS.PRO_PLUS_YEARLY,
      PLAN_IDS.ENTERPRISE_BUSINESS_MONTHLY,
      PLAN_IDS.ENTERPRISE_BUSINESS_QUARTERLY,
      PLAN_IDS.ENTERPRISE_BUSINESS_YEARLY,
    ]
    const proPlusCanRequest =
      proPlusPlans.includes(this.planId()) && this.owner()
    return (proPlusCanRequest || this.teamOwner()) && !this.enterpriseApi()
  }

  eligibleForOffer() {
    const planId = this.planId()
    const BFCM_2023_INELEGIBLE_PLAN_IDENTIFIERs = [
      PLAN_IDS.STRAYNOTE_YEARLY,
      "pro-renderstudios",
    ]

    return (
      !this.enterprise() &&
      !this.enterpriseApi() &&
      !this?.subscription?.isEnterpriseBusiness() &&
      !isInternationalTestPlan(planId) &&
      !BFCM_2023_INELEGIBLE_PLAN_IDENTIFIERs.includes(planId)
    )
  }

  eligibleForEnterpriseUpsell() {
    return !this.enterprise() && PRO_PRO_PLUS_IDS.includes(this.planId())
  }

  showSongRowCart() {
    return this.song_row_cart
  }
}

export default User
