import { createRelationships } from "records/Relationship"
import Immutable from "immutable"
import { secondsToMMSS } from "utils/formatting"
import Pic from "./Pic"
import _toString from "lodash/toString"

const MarketplaceArtistInfoRecord = Immutable.Record({
  id: null,
  name: null,
  slugified_name: null,
  pic: Pic,
})

export class MarketplaceArtistInfo extends MarketplaceArtistInfoRecord {}

const MarketplaceContentPartnerInfoRecord = Immutable.Record({
  name: "",
  slug: "",
  orfium_integration: "",
})

export class MarketplaceContentPartnerInfo extends MarketplaceContentPartnerInfoRecord {}

const MarketplaceSongRecord = Immutable.Record(
  {
    id: null,
    type: "marketplace_songs",

    // Attributes
    apple_id: null,
    archived_at: null,
    artist_credits: [],
    audio_files: [],
    bpm: "",
    characteristic_tags: [],
    content_partner: {},
    description: "",
    duration: null,
    energy: "",
    explicit: false,
    featured: false,
    featured_position: null,
    genre_tags: [],
    has_vocals: false,
    instrument_tags: [],
    isrc: null,
    key: "",
    keywords: [],
    lyrics: "",
    metadata: {},
    mood_tags: [],
    new_release: false,
    pic: Pic,
    playlists: null,
    primary_audio_file_id: null,
    released_at: null,
    single_instrument: false,
    song_tier: null,
    song_type: null,
    spotify_id: null,
    state: null,
    title: "",
    transloadit_waveform_data_assembly_id: "",
    tuned_global_id: null,
    waveform_data: [],

    // Relationships
    relationships: {
      audio_files: {},
    },
  },
  "MarketplaceSong"
)

const relationshipsTypes = {
  audio_files: ["marketplace_audio_files"],
}

class MarketplaceSong extends MarketplaceSongRecord {
  constructor(args = {}) {
    const { audio_files: audioFiles, tags } = args
    let artistCredits =
      args.artist_credits == null || args.artist_credits.length === 0
        ? []
        : args.artist_credits

    if (args.artists) {
      artistCredits = args.artists
    }

    super(
      Object.assign({}, args, {
        artist_credits:
          artistCredits.length === 0
            ? [new MarketplaceArtistInfo()]
            : artistCredits.map(
                (artistInfo) => new MarketplaceArtistInfo(artistInfo)
              ),
        characteristic_tags: tags
          ? tags.characteristic
          : args.characteristic_tags,
        genre_tags: tags ? tags.genre : args.genre_tags,
        instrument_tags: tags ? tags.instrument : args.instrument_tags,
        mood_tags: tags ? tags.mood : args.mood_tags,
        content_partner: new MarketplaceContentPartnerInfo(
          args.content_partner
        ),
        primary_artist_info: new MarketplaceArtistInfo(artistCredits[0]),
        primary_audio_file_id:
          audioFiles && audioFiles[0]
            ? audioFiles.find((a) => a.primary)?.id
            : args.primary_audio_file_id,
        relationships: args.relationships
          ? createRelationships(args.relationships, relationshipsTypes)
          : {},
      })
    )
  }

  primaryArtistId() {
    return this.artist_credits.length === 0 ? null : this.artist_credits[0].id
  }

  primaryArtistName() {
    return this.artist_credits.length === 0 ? null : this.artist_credits[0].name
  }

  primaryArtistSlugifiedName() {
    return this.artist_credits.length === 0
      ? null
      : this.artist_credits[0].slugified_name
  }

  durationFormatted() {
    return secondsToMMSS(this.duration)
  }

  popular() {
    return this.song_type === "Marketplace::PopularSong"
  }

  stock() {
    return this.song_type === "Marketplace::StockSong"
  }

  contentType() {
    if (this.stock()) {
      return "Production"
    } else if (this.popular()) {
      return "Popular"
    }
  }

  hasOrfium() {
    return this.content_partner.orfium_integration !== "disabled"
  }

  primaryAudioFileId() {
    return _toString(this.primary_audio_file_id)
  }

  isArchived() {
    if (this.archived_at === null) return false
    const currentDate = new Date()
    const archivedDateTime = new Date(this.archived_at)
    const timeDifferenceInMilliseconds = currentDate - archivedDateTime

    return timeDifferenceInMilliseconds > 48 * 60 * 60 * 1000
  }
}

export default MarketplaceSong
