import { useEffect, useState } from "react"

const storeJS = require("utils/store.min.js")

const useLocalStorage = (key) => {
  const [state, setState] = useState(storeJS.get(key))

  useEffect(() => {
    setState(storeJS.get(key))
    const handleStorageChange = () => setState(storeJS.get(key))
    window.addEventListener(`storage:${key}`, handleStorageChange)

    return () => {
      window.removeEventListener(`storage:${key}`, handleStorageChange)
    }
  }, [key])

  return {
    value: state,
    set: (newValue) => {
      storeJS.set(key, newValue)
      setState(newValue)
      window.dispatchEvent(new Event(`storage:${key}`))
    },
    remove: () => {
      storeJS.remove(key)
      setState(null)
      window.dispatchEvent(new Event(`storage:${key}`))
    },
  }
}

export default useLocalStorage
