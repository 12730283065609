import { createRelationships } from "records/Relationship"
import Immutable from "immutable"
import _toString from "lodash/toString"
import { buildImgixUrl } from "utils/imgix"
import Pic from "./Pic"

const ArtistInfoRecord = Immutable.Record({
  id: null,
  pic: {
    url: null,
    thumbnail_url: null,
  },
  name: null,
})

export class ArtistInfo extends ArtistInfoRecord {
  picUrl() {
    return buildImgixUrl(this.pic.url)
  }

  picThumbnailUrl() {
    return buildImgixUrl(this.pic.url, { w: 100, h: 100 })
  }
}

const SongRecord = Immutable.Record(
  {
    id: null,
    type: "songs",

    // Attributes
    apple_id: null,
    archived: false,
    artist_credits: [],
    audio_files: [],
    bpm_data: {},
    bpm: "",
    characteristic_tags: [],
    content_id_registered: false,
    cyanite_keywords: [],
    description: "",
    energy: "",
    explicit: false,
    free: null,
    genre_tags: [],
    has_instrumental_version: false,
    has_stems: false,
    has_vocal_version: false,
    instrument_tags: [],
    key: "",
    lyrics: "",
    mood_tags: [],
    new_release: false,
    performer_credits: "",
    primary_artist_info: ArtistInfo,
    primary_audio_file_id: null,
    producer_credits: "",
    recommended_song_ids: [],
    secondary_audio_file_ids: [],
    single_instrument: false,
    spotify_id: null,
    state: null,
    stems_download_url: null,
    title: "",
    writer_credits: "",
    waveform_data: [],
    duration: null,
    daaci_id: "",

    // Relationships
    relationships: {
      audio_files: {},
    },
  },
  "Song"
)

const relationshipsTypes = {
  audio_files: ["audio_files"],
}

class Song extends SongRecord {
  constructor(args = {}) {
    const { audio_files: audioFiles, tags } = args
    let artistCredits =
      args.artist_credits == null || args.artist_credits.length === 0
        ? []
        : args.artist_credits

    if (args.artists) {
      artistCredits = args.artists
    }

    super(
      Object.assign({}, args, {
        artist_credits:
          artistCredits.length === 0
            ? [new ArtistInfo()]
            : artistCredits.map((artistInfo) => new ArtistInfo(artistInfo)),
        characteristic_tags: tags
          ? tags.characteristic
          : args.characteristic_tags,
        genre_tags: tags ? tags.genre : args.genre_tags,
        instrument_tags: tags ? tags.instrument : args.instrument_tags,
        mood_tags: tags ? tags.mood : args.mood_tags,
        performer_credits:
          args.artists && args.artists[0]
            ? args.artists[0].name
            : args.performer_credits,
        primary_artist_info: new ArtistInfo(artistCredits[0]),
        primary_audio_file_id:
          audioFiles && audioFiles[0]
            ? audioFiles.find((a) => a.primary).id
            : args.primary_audio_file_id,
        producer_credits: args.producers
          ? args.producers[0]
          : args.producer_credits,
        pic: new Pic(args.pic),
        relationships: args.relationships
          ? createRelationships(args.relationships, relationshipsTypes)
          : {},
        writer_credits: args.writers ? args.writers[0] : args.writer_credits,
      })
    )
  }

  primaryArtistId() {
    return this.artist_credits.length === 0 ? null : this.artist_credits[0].id
  }

  primaryArtistName() {
    return this.artist_credits.length === 0 ? null : this.artist_credits[0].name
  }

  primaryArtistPicUrl() {
    return this.artist_credits.length === 0
      ? null
      : this.artist_credits[0].picUrl()
  }

  primaryArtistPicThumbnailUrl() {
    return this.artist_credits.length === 0
      ? null
      : this.artist_credits[0].picThumbnailUrl()
  }

  primaryAudioFileId() {
    return _toString(this.primary_audio_file_id)
  }
}

export default Song
