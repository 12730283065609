import React from "react"
import Box from "components/Box"
import useCurrentUser from "hooks/useCurrentUser"
import { Text } from "components/Typography"

const UserNotificationsCount = () => {
  const { currentUser } = useCurrentUser()
  const notifCount = currentUser.unread_notification_count

  if (notifCount === 0) return null

  return (
    <Box
      ml={2}
      width="24px"
      height="24px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="brand.pinkJoy"
      borderRadius="default"
    >
      <Text fontSize="sm" lineHeight="tight" color="white" fontWeight="medium">
        {notifCount}
      </Text>
    </Box>
  )
}

export default UserNotificationsCount
