import styled from "styled-components"

const SongType = styled.div`
  display: none;
  flex: 1 0 96px;
  flex-wrap: wrap;
  margin-left: ${({ theme, ml }) => ml || theme.space[3]};
  margin-right: ${({ theme }) => theme.space[3]};
  max-height: 45px;

  &:not(.header) {
    overflow: hidden;
    padding: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
  }
`

export default SongType
