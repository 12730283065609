import PropTypes from "prop-types"
import React, { memo } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { Song as SongRecord } from "records"
import SongRequest from "components/SongRequest"
import { actions as audioPlayerActions } from "ducks/audioPlayer"
import { actions as modalActions } from "ducks/modal"
import useMixpanelTracking from "hooks/useMixpanelTracking"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.layered};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  width: 100%;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 500;
  border-bottom: 2px solid ${({ theme }) => theme.audioPlayer.borderColor};
  border-top-left-radius: 5px;
`

const Option = styled.div`
  cursor: pointer;
  border: 2px transparent;
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.primary};
  vertical-align: middle;
  line-height: 50px;
  padding-left: 1rem;
  transition: all ${({ theme }) => theme.transitions.quick};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: ${({ theme }) => theme.colors.background.elevationLow};
    text-decoration: underline;
  }
`

const MorePopover = ({ song }) => {
  const dispatch = useDispatch()
  const { trackMixpanel } = useMixpanelTracking()

  const handleShare = () => {
    trackMixpanel("Clicked Element", {
      element: "Share Button",
      context: "Song: Audio Player Ellipses Menu",
      "Song ID": song.id,
    })

    openModal("SocialShareModal", {
      pathName: `/songs/${song.id}`,
      recordType: "song",
    })
  }

  const handleShowCredits = () => {
    openModal("SongCreditsModal", { song })
  }

  const handleDownload = () => {
    openModal("DownloadSongModal", { songId: song.id })
  }

  const openModal = (modalKey, data) => {
    dispatch(modalActions.open(modalKey, data))
  }

  const handleClosePlayer = () => {
    dispatch(audioPlayerActions.stop())
  }

  return (
    <Wrapper>
      <Option onClick={handleShare}>Share</Option>
      <Option onClick={handleShowCredits}>View Credits</Option>
      <SongRequest
        component={Option}
        onClick={handleDownload}
        song={song}
        isLicense
      >
        Download
      </SongRequest>
      <Option onClick={handleClosePlayer}>Close Player</Option>
    </Wrapper>
  )
}

MorePopover.propTypes = {
  song: PropTypes.instanceOf(SongRecord).isRequired,
}

export default memo(MorePopover)
