const trackMixpanelMarketplaceMusicSearch = (searchState) => {
  const {
    mood,
    characteristic,
    instrument,
    genre,
    energy,
    songTiers,
    songType,
  } = searchState
  const searchQuery = searchState.searchQuery || ""

  const activeEnergy =
    (energy?.activeFilters &&
      energy.activeFilters.map((e) => e.toLowerCase().replace(/\s/g, "_"))) ||
    []

  const activeCharacteristics = characteristic?.activeFilters || []
  const activeGenres = genre?.activeFilters || []
  const activeInstruments = instrument?.activeFilters || []
  const activeMoods = mood?.activeFilters || []

  const activeTags = activeEnergy.concat(
    activeCharacteristics,
    activeGenres,
    activeInstruments,
    activeMoods
  )

  const activeSongTiers = songTiers?.activeFilters || []

  const SONG_TYPE_DISPLAY = {
    "Marketplace::PopularSong": "Popular",
    "Marketplace::StockSong": "Production",
  }
  const activeSongType = songType?.activeFilters?.[0] || ""
  const songTypeDisplay = SONG_TYPE_DISPLAY[activeSongType] || null

  const properties = {
    marketplace: true,
    Query: searchQuery,
    Tags: activeTags,
    "Song Tiers": activeSongTiers,
    "Song Type": songTypeDisplay,
  }

  return properties
}

export default trackMixpanelMarketplaceMusicSearch
