import styled from "styled-components"

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex: 0 0 130px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 0 0 232px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex: 0 0 264px;
  }

  &.condensedLayout {
    flex: 0 0 auto;
  }
`

export default Actions
