import { delay, select, put, take, race, takeEvery } from "redux-saga/effects"
import { actions as modalActions } from "ducks/modal"
import {
  types as currentUserTypes,
  selectCurrentUserRecord,
} from "ducks/currentUser"
import { types as interactionTypes } from "ducks/interactions"
import { types as modalTypes } from "ducks/modal"

export function* buySongLicense(action) {
  try {
    const user = yield select(selectCurrentUserRecord())

    if (!user.id) {
      yield put(modalActions.open("SignUpModal", { action: "license" }))

      const { cancelled, authenticated } = yield race({
        authenticated: take([
          currentUserTypes.REQUEST_SUCCESS,
          currentUserTypes.SIGN_IN_SUCCESS,
        ]), // technically this should be `AUTHENTICATED` or something like that
        cancelled: take(modalTypes.CLOSE),
      })

      if (cancelled && !authenticated) return
    }

    // just wait for all the other random stuff that happens post sign-in to finish. when side effects are not called
    // haphazardly from components, we won't need this
    yield delay(300)

    // eslint-disable-next-line no-constant-condition
    if (action.featuredBuying) {
      yield put(
        modalActions.open("BuyingOptionsModal", { sellable: action.song })
      )
    } else {
      yield put(
        modalActions.open("SingleSongLicenseModal", { songs: [action.song] })
      )
    }
  } catch (err) {
    console.error(err)
  }
}

export function* buySongAfterPreviewLicensingOptions(action) {
  try {
    const user = yield select(selectCurrentUserRecord())

    if (!user.id) {
      yield put(modalActions.open("SignUpModal", { action: "download" }))

      const { cancelled } = yield race({
        authenticate: take(currentUserTypes.REQUEST_SUCCESS), // technically this should be `AUTHENTICATED` or something like that
        cancelled: take(modalTypes.CLOSE),
      })

      if (cancelled) return
    }

    // just wait for all the other random stuff that happens post sign-in to finish. when side effects are not called
    // haphazardly from components, we won't need this
    yield delay(300)

    // eslint-disable-next-line no-constant-condition
    if (
      action.simplifiedPreviewDownloads &&
      !user.hasSubscription() &&
      !user.licensedSongIds().includes(action.song.id)
    ) {
      yield put(
        modalActions.open("LicensingOptionsModal", { sellable: action.song })
      )
    }
  } catch (err) {
    console.error(err)
  }
}

export function* watchBuySongLicense() {
  yield takeEvery(interactionTypes.BUY_SONG_LICENSE, buySongLicense)
}

export function* watchBuySongAfterPreviewLicensingOptions() {
  yield takeEvery(
    interactionTypes.BUY_SONG_AFTTER_PREVIEW_LICENSING_OPTIONS,
    buySongAfterPreviewLicensingOptions
  )
}

export default [watchBuySongLicense, watchBuySongAfterPreviewLicensingOptions]
