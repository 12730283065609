import useFlag from "hooks/useFlag"
import { useDebugFlag } from "context/DebugFlagContext"
import useCurrentUser from "hooks/useCurrentUser"

export default () => {
  const { debugFlags } = useDebugFlag()
  const { currentUser } = useCurrentUser()

  const {
    enabled: flagFullyEnabled,
    enabledGroups,
    loading: flagLoading,
  } = useFlag("app_notifications")

  const debugFlagEnabled = debugFlags["APP_NOTIFICATIONS"]

  const userInEnabledGroup =
    enabledGroups?.includes("test_users") && currentUser?.test_user

  return {
    enabled: debugFlagEnabled || userInEnabledGroup || flagFullyEnabled,
    loading: flagLoading,
  }
}
