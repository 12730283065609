import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import styled, { withTheme } from "styled-components"
import { withRouter } from "react-router"
import useAppNotifications from "hooks/useAppNotifications"
import { User as UserRecord } from "records"
import Avatar from "components/Avatar"
import Box from "components/Box"
import Dropdown from "./Dropdown"
import { Text } from "components/Typography"

const DEFAULT_AVATAR_IMAGE =
  "https://cdn.soundstripe.com/assets/images/fallback/default_image_thumb.png"

const DropDownWrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${(props) => props.theme.space[2]};
  text-align: left;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0 ${(props) => props.theme.space[3]};
  }
`

const UserInfoSection = ({ currentUser, location }) => {
  const { enabled: appNotificationsEnabled } = useAppNotifications()
  const [showMenu, setShowMenu] = useState(false)
  const dropdownMenuRef = useRef(null)

  const showNotificationIndicator =
    appNotificationsEnabled && currentUser.unread_notification_count > 0

  const onOutsideClick = (event) => {
    if (
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target)
    ) {
      closeMenu()
    }
  }

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("click", onOutsideClick)
    }
    return () => {
      document.removeEventListener("click", onOutsideClick)
    }
  }, [showMenu])

  useEffect(() => {
    if (showMenu) {
      closeMenu()
    }
  }, [location])

  const showMenuHandler = (event) => {
    event.preventDefault()
    setShowMenu(true)
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  const image = currentUser.avatar.thumbnailUrl() || DEFAULT_AVATAR_IMAGE

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      data-cy="sign_in-success"
      position="relative"
    >
      <DropDownWrapper
        aria-label="Account menu"
        data-cy="user-nav-dropdown-btn"
        onClick={showMenuHandler}
      >
        <Box position="relative">
          <Avatar
            avatarUrl={image}
            id={currentUser.id}
            initials={currentUser.initials()}
            size="lg"
          />

          {showNotificationIndicator && (
            <Box
              border="2px solid"
              borderColor="background.primary"
              backgroundColor="brand.pinkJoy"
              borderRadius="circle"
              top="-8px"
              right="-12px"
              position="absolute"
              width="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="24px"
            >
              <Text
                fontSize="xs"
                color="white"
                fontWeight="medium"
                lineHeight="tight"
              >
                {currentUser.unread_notification_count}
              </Text>
            </Box>
          )}
        </Box>
      </DropDownWrapper>
      {showMenu && (
        <div ref={dropdownMenuRef}>
          <Dropdown />
        </div>
      )}
    </Box>
  )
}

UserInfoSection.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.instanceOf(UserRecord).isRequired,
}

export default withRouter(withTheme(UserInfoSection))
