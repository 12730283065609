import { combineReducers } from "redux-immutable"
import aiSongEditor from "./aiSongEditor"
import algoliaList from "./algoliaList"
import api from "./api"
import cart from "./cart"
import audioPlayer from "./audioPlayer"
import currentUser from "./currentUser"
import frameio from "./frameio"
import infiniteList from "./infiniteList"
import list from "./list"
import marketplace from "./marketplace"
import modal from "./modal"
import playlist from "./playlist"
import referral from "./referral"
import search from "./search"
import searchFilter from "./searchFilter"
import selectable from "./selectable"
import sidebar from "./sidebar"
import soundEffectsPlayer from "./soundEffectsPlayer"
import subscription from "./subscription"
import tracking from "./tracking"
import ui from "./ui"
import urlHistory from "./urlHistory"
import flag from "./flag"
import checkout from "./checkout"
import interactions from "./interactions"

export const reducers = {
  aiSongEditor,
  algoliaList,
  api,
  audioPlayer,
  cart,
  currentUser,
  frameio,
  infiniteList,
  list,
  marketplace,
  modal,
  playlist,
  referral,
  search,
  searchFilter,
  selectable,
  sidebar,
  soundEffectsPlayer,
  subscription,
  tracking,
  ui,
  urlHistory,
  flag,
  checkout,
  interactions,
}

const reducer = combineReducers(reducers)

export default reducer
